import {useEffect, useState} from "react";
import {computeFixedHeaderOffset} from "../utils/computeFixedHeaderOffset";

export function useHeaderOffset(enabled: boolean = true) {
  const [headerOffset, setHeaderOffset] = useState<number|undefined>(undefined)

  useEffect(() => {
    if(enabled) {
      setHeaderOffset(computeFixedHeaderOffset());

      const interval = setInterval(() => {
        setHeaderOffset(computeFixedHeaderOffset())
      }, 500)

      return () => clearInterval(interval)
    }
  }, [enabled]);

  return headerOffset
}
