import loadable from "@loadable/component";
import { CircleLoader } from "@webng/icons-common";
import React from "react";
import { Settings } from "react-slick";

const LazySlickSlider = loadable(() => import('react-slick').then(slick => slick.default), {
	fallback: <div className="tik4-sl tik4-tws">
		<CircleLoader className="tik4-icon tik4-spin"/>
	</div>
})

export function TeaserSliderWrapper({children, ...settings} : (Settings & {children: React.ReactNode})) {
	return <LazySlickSlider {...settings}>{children}</LazySlickSlider>
}

export function preloadTeaserSliderWrapper() {
  import('react-slick')
}
