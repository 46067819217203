declare var IS_PRODUCTION: boolean;

import {ensureCss} from "../utils/styleTags";
import {findClientScriptTag} from "./globalSettings";
import globalSettings from "../tik4/globalSettings"
import '../../css/tik4.css'


if (globalSettings.localDev && IS_PRODUCTION) {
  // Local Dev mode, activate with #tickaroo_local_dev=true
  const localScript = findClientScriptTag();
  const newScript = document.createElement('script');
  newScript.async = true;
  newScript.src = "http://localhost:8040/webng/embedjs/tik4.js";
  document.head.appendChild(newScript);
  if (localScript) {
    localScript.remove();
  }
} else {
  (function() {
    ensureCss("tik4.css")

    import('./index').then(() => {
      console.debug('Tik4 application loaded successfully');
    }).catch(error => {
      console.error('Failed to load Tik4 application:', error);
    });
  })();
}
