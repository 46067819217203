import React, {useCallback} from "react";

// import {Star} from "react-feather";
import {IEvent} from "@webng-types/write-model";
import {getHighlightPostText} from "../utils/eventUtils";
import {OnSummaryItemClickHandler} from "../Liveblog";
import {useNiceDateTime} from "@webng/react-app-common/src";

// import YellowCard from "@webng/sports/icons/soccer/yellow-card";

interface TeaserWidgetListItemProps {
  event: IEvent
  liveblogUrl: string
  onClick?: OnSummaryItemClickHandler
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void
}

export const TeaserWidgetListItem = React.forwardRef<HTMLAnchorElement, TeaserWidgetListItemProps>(function TeaserWidgetListItemImp({event, liveblogUrl, onClick, onKeyDown}, ref) {
  const onClickCallback = useCallback(() => {
    onClick && onClick(event)
  }, [onClick, event])
  const {date, time} = useNiceDateTime(event.created_at);

  const content = getHighlightPostText(event);

  // const IconTemp = 1 >= 2 ? YellowCard : false;
  const IconTemp = false;

  if (!content) {
    return <></>;
  }

  return <div className={`tik4-twl__item ${event.highlight ? "tik4-twl__item--highlight" : ""} ${IconTemp ? "tik4-twl__item--wi" : ""}`} role="listitem">
    <a href={`${liveblogUrl}#tickaroo_event_id=${encodeURIComponent(event.local_id!)}`} className="tik4-twl__c" onClick={onClickCallback} onKeyDown={onKeyDown} tabIndex={0} ref={ref}>
      <div className="tik4-twl__tl tik4-twl__tl--1"><div className="tik4-twl__tl__b"></div></div>
      <div className="tik4-twl__tl tik4-twl__tl--2"><div className="tik4-twl__tl__b"></div></div>
      <div className="tik4-twl__icons">
        <div className="tik4-twl__icons-container">
          {/* {IconTemp && <IconTemp className="tik4-twl__icon" /> } */}
          <div className="tik4-twl__tli"></div>
        </div>
      </div>
      <div className="tik4-twl__h">
        <div className="tik4-twl__h__c">
          <div className="tik4-twl__dt">
            {date &&
              <>
                <span className="tik4-twl__dt__d">{date}</span>
                <span className="tik4-twl__dt__div"><span className="tik4-twl__dt__div__c">,</span></span>
              </>
            }
            <span className="tik4-twl__dt__t">{time}</span>
          </div>
        </div>

      </div>
      <div className="tik4-twl__content">{ getHighlightPostText(event) }</div>
    </a>
  </div>
})
