import {
  LiveblogApi,
  LiveblogWidgetQueryProps,
  LiveblogWidgetQueryPropsParser,
  LiveblogWidgetTagProps,
  liveblogWidgetTagPropsParser,
} from "./liveblog/LiveblogWidgetProps";
import {
  LiveblogThemeConfiguration,
  liveblogThemeConfigurationPropsParser,
  SharedThemeConfiguration,
  sharedThemeConfigurationPropsParser
} from "@webng-types/embedjs";
import {notInitialized} from "../utils/notInitialized";
import {AdManager} from "../ads/AdManager";
import {NavigateToEventIdOptions} from "./liveblog-core/LiveblogCoreWidgetProps";
import React from "react";
import {AbstractLiveblogElement} from "./AbstractLiveblogElement";
import {PropsParser} from "@webng/validations";
import {LiveblogWidget} from "./liveblog/LiveblogWidget";

const propsParser: PropsParser<LiveblogWidgetTagProps> = {
  ...liveblogWidgetTagPropsParser,
};

export class TickarooLiveblogElement
  extends AbstractLiveblogElement<LiveblogThemeConfiguration, LiveblogWidgetTagProps, LiveblogWidgetQueryProps, LiveblogApi>
  implements LiveblogApi
{

  static get observedAttributes() {
    return [
      ...Object.keys(propsParser).map((x) => x.toLowerCase()),
      ...Object.keys(liveblogThemeConfigurationPropsParser).map((x) =>
        x.toLowerCase()
      ),
      ...Object.keys(sharedThemeConfigurationPropsParser).map((x) =>
        x.toLowerCase()
      ),
    ];
  }


  constructor() {
    super({...liveblogThemeConfigurationPropsParser, ...sharedThemeConfigurationPropsParser}, liveblogWidgetTagPropsParser, LiveblogWidgetQueryPropsParser);
  }

  hasEventIdLoaded(eventId: string): boolean {
    if (this.liveblogComponent) {
      return this.liveblogComponent.hasEventIdLoaded(eventId);
    } else {
      return notInitialized();
    }
  }

  loadMoreBottom(): PromiseLike<void> {
    if (this.liveblogComponent) {
      return this.liveblogComponent.loadMoreBottom();
    } else {
      return notInitialized();
    }
  }

  loadMoreTop(): PromiseLike<void> {
    if (this.liveblogComponent) {
      return this.liveblogComponent.loadMoreTop();
    } else {
      return notInitialized();
    }
  }

  setConsent(provider: string, consent: boolean): void {
    if (this.liveblogComponent) {
      return this.liveblogComponent.setConsent(provider, consent);
    } else {
      return notInitialized();
    }
  }

  setAdManager(newAdManager: AdManager): void {
    if (this.liveblogComponent) {
      return this.liveblogComponent.setAdManager(newAdManager)
    } else {
      return notInitialized()
    }
  }

  navigateToEventId(eventId: string, options?: NavigateToEventIdOptions): PromiseLike<boolean> {
    if (this.liveblogComponent) {
      return this.liveblogComponent.navigateToEventId(eventId, options);
    } else {
      return notInitialized();
    }
  }

  useHydrate(props: {
    tagProps: LiveblogWidgetTagProps;
    queryProps: LiveblogWidgetQueryProps;
    overrideConfigurationProps: Partial<LiveblogThemeConfiguration & SharedThemeConfiguration>
  }): boolean {
    return !!props.tagProps.initialData.configuration;
  }

  renderImp(ref: (r: LiveblogApi) => void, props: {
    tagProps: LiveblogWidgetTagProps;
    queryProps: LiveblogWidgetQueryProps;
    overrideConfigurationProps: Partial<LiveblogThemeConfiguration & SharedThemeConfiguration>
  }): React.ReactNode {
    const finalProps = Object.assign({}, props.tagProps, props.queryProps, {overrideConfiguration: props.overrideConfigurationProps});
    return <LiveblogWidget ref={ref} {...finalProps} />
  }
}
