import i18n, {detectHtmlLanguage} from "./i18n";
import React, {useEffect, useMemo} from "react";
import {IGameShowResponse} from "@webng-types/write-model";
import {I18nextProvider} from "react-i18next";

interface I18nWrapperProps {
  locale: string|undefined;
  gameShowResponse: IGameShowResponse|undefined
  children: React.ReactNode;
}

export function I18nWrapper({ locale, gameShowResponse, children }: I18nWrapperProps) {
  // Cloning i18n using the same language rule as the server (either set by config or english as default)
  // This ensures that hydrate finds the same html client and server
  const initLocale = locale || gameShowResponse?.game?.locale || ''
  const derivedI18n = useMemo(() => {
    return i18n.cloneInstance({
      lng: initLocale || 'en'
    })
  }, [initLocale])

  // Change the language after first rendering if auto detection is enabled
  useEffect(() => {
    if (initLocale === "") {
      derivedI18n.changeLanguage(detectHtmlLanguage())
    }
  }, [derivedI18n, initLocale])

  return <I18nextProvider i18n={derivedI18n}>
    {children}
  </I18nextProvider>
}
