import React, {ReactNode} from "react";
import {IScoreboardScore, ITeamGameScoreboard} from "@webng-types/write-model";
import {TeamGameScoreboardDisplayTypes} from "@webng/sports";
import classNames from "classnames";
import EventTeamIcon from "../event/EventTeamIcon";


function Score({score}: {score?: IScoreboardScore}){
  return <>
    <div className="tik4-sb__s tik4-sb__s--ht">{score?.home_score || "–"}</div>
    <div className="tik4-sb__d">:</div>
    <div className="tik4-sb__s tik4-sb__s--at">{score?.away_score || "–"}</div>
  </>
}

interface TeamGameScoreboardProps {
  scoreboard: ITeamGameScoreboard;
  isMain?: boolean;
  scoreComponent?: (score?: IScoreboardScore) => ReactNode;
}

export function TeamGameScoreboard({scoreboard, scoreComponent, isMain = false}: TeamGameScoreboardProps) {
  const imageSize = isMain ? 40 : 32;
  // function dprImage(image?:string) {
  //   if (image) {
  //     const image1x = exactSizeImageUrl(image, {width: imageSize, height: imageSize, fit: 'contain'})
  //     const image2x = exactSizeImageUrl(image, {width: imageSize*2, height: imageSize*2, fit: 'contain'})
  //     const image3x = exactSizeImageUrl(image, {width: imageSize*3, height: imageSize*3, fit: 'contain'})
  //     const image4x = exactSizeImageUrl(image, {width: imageSize*4, height: imageSize*4, fit: 'contain'})
  //     return {
  //       src: image1x,
  //       srcSet: `
  //       ${image4x.url} 4x,
  //       ${image3x.url} 3x,
  //       ${image2x.url} 2x,
  //       ${image1x.url} 1x
  //       `
  //     }
  //   } else {
  //     return undefined;
  //   }
  // }
  // const homeTeamImage = useMemo(() => {
  //   return dprImage(scoreboard.home_team?.image)
  // }, [scoreboard.home_team])
  //
  // const awayTeamImage = useMemo(() => {
  //   return dprImage(scoreboard.away_team?.image)
  // }, [scoreboard.away_team])

  const displayType = scoreboard.display_type || TeamGameScoreboardDisplayTypes.DEFAULT

  const ht_short_name = scoreboard.home_team?.short_name || scoreboard.home_team?.name
  const ht_name = scoreboard.home_team?.name || scoreboard.home_team?.short_name

  const at_short_name = scoreboard.away_team?.short_name || scoreboard.away_team?.name
  const at_name = scoreboard.away_team?.name || scoreboard.away_team?.short_name

  return <div className={classNames(
    "tik4-sb",
    {
      "tik4-sb--d": displayType === TeamGameScoreboardDisplayTypes.DEFAULT,
      "tik4-sb--d tik4-sb--d-l": displayType === TeamGameScoreboardDisplayTypes.DEFAULT_LOGO,
      "tik4-sb--d tik4-sb--d-t": displayType === TeamGameScoreboardDisplayTypes.DEFAULT_TEXT,
      "tik4-sb--t": displayType === TeamGameScoreboardDisplayTypes.TABLE,
      "tik4-sb--t tik4-sb--t-t": displayType === TeamGameScoreboardDisplayTypes.TABLE_TEXT,
      "tik4-sb--m": isMain,
      "tik4-sb--e": !isMain
    }
    )}>

    { scoreboard.phase?.name &&
      <div className="tik4-sb__ph">{scoreboard.phase?.name}</div>
    }

    { scoreboard.time_of_play &&
      <div className="tik4-sb__top">{scoreboard.time_of_play}</div>
    }

    {/* spacer */}
    <div className="tik4-sb__sp"></div>

    <div className="tik4-sb__t tik4-sb__t--ht">
      <div className="tik4-sb__t__i">
        <EventTeamIcon
            className="tik4-sb__t__img"
            placeholderClassName="tik4-sb__t__iph"
            image={scoreboard.home_team?.image}
            size={imageSize}
            name={scoreboard.home_team?.short_name || scoreboard.home_team?.name} />
      </div>
      <div className="tik4-sb__t__n tik4-sb__t__n--ht">
        <div className="tik4-sb__t__n__sn">{ht_short_name}</div>
        <div className="tik4-sb__t__n__n">{ht_name}</div>
      </div>
    </div>

    {scoreComponent ? scoreComponent(scoreboard.score) : <Score score={scoreboard.score}/>}


    <div className="tik4-sb__t tik4-sb__t--at">
      <div className="tik4-sb__t__i">
        <EventTeamIcon
            className="tik4-sb__t__img"
            placeholderClassName="tik4-sb__t__iph"
            image={scoreboard.away_team?.image}
            size={imageSize}
            name={scoreboard.away_team?.short_name || scoreboard.away_team?.name} />
      </div>
      <div className="tik4-sb__t__n tik4-sb__t__n--at">
        <div className="tik4-sb__t__n__sn">{at_short_name}</div>
        <div className="tik4-sb__t__n__n">{at_name}</div>
      </div>
    </div>

  </div>
}
