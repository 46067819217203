import React, {createContext, ForwardedRef, forwardRef, ReactNode, useContext, useMemo} from "react";
import classNames from "classnames";
import useBreakpoints from "@webng/react-app-common/src/utils/useBreakpoints";

import {ColorScheme, TeaserLayoutLiveIndicatorStyleType, TeaserLayoutType, TeaserTimelineType} from "../types";
// import {
//   teaserLayoutValues
// } from "@webng-types/embedjs";


interface TeaserWrapperProps {
  liveblogId: string
  invertTime: boolean
  colorScheme: ColorScheme
  layout: TeaserLayoutType
  layoutTimeline: TeaserTimelineType
  layoutLiveIndicatorStyle: TeaserLayoutLiveIndicatorStyleType
  styleBgColor: string
  styleBgColorDark: string
  styleTextColor: string
  styleTextColorDark: string
  styleTextMutedColor: string
  styleTextMutedColorDark: string
  styleLinkColor: string
  styleLinkColorDark: string
  styleSurfaceColor: string
  styleSurfaceColorDark: string
  css?: string
  numberOfEvents: number
  children: ReactNode
}

interface CssVariables {
  // '--t4-primary--l'?: string
  // '--t4-primary--d'?: string
  // '--t4-secondary--l'?: string
  // '--t4-secondary--d'?: string
  // '--t4-txt--l'?: string
  // '--t4-txt--d'?: string
  // '--t4-muted--l'?: string
  // '--t4-muted--d'?: string
  // '--t4-bg--l'?: string
  // '--t4-bg--d'?: string
  // '--t4-ev-h-bg--l'?: string
  // '--t4-ev-h-bg--d'?: string
  // '--t4-ev-p-bg--l'?: string
  // '--t4-ev-p-bg--d'?: string
  // '--t4-ev-s-bg--l'?: string
  // '--t4-ev-s-bg--d'?: string
  // '--t4-pu-t'?: string

   /* background */
  '--t4-tw-bg--l'?: string
  '--t4-tw-bg--d'?: string

  /* text */
  '--t4-tw-txt--l'?: string
  '--t4-tw-txt--d'?: string

  /* muted text */
  '--t4-tw-muted--l'?: string
  '--t4-tw-muted--d'?: string

  /* link */
  '--t4-tw-link--l'?: string
  '--t4-tw-link--d'?: string

  /* surface */
  '--t4-tw-sfc--l'?: string
  '--t4-tw-sfc--d'?: string
}

interface ReactWrapperContextType {
  className: string,
  style: CssVariables
  layout: TeaserLayoutType
}


const ReactWrapperContext = createContext<ReactWrapperContextType>({className: "", style: {}, layout: 'slider'})

export function TeaserWrapperImp({liveblogId, invertTime, children, colorScheme, layout, layoutTimeline, layoutLiveIndicatorStyle, styleTextColor, styleTextColorDark, styleTextMutedColor, styleTextMutedColorDark, styleLinkColor, styleLinkColorDark, styleSurfaceColor, styleSurfaceColorDark, styleBgColor, styleBgColorDark, css, numberOfEvents} : TeaserWrapperProps, ref: ForwardedRef<HTMLDivElement>) {

  const style = useMemo(() => {
    let retStyle: CssVariables = {}
    if(styleTextColor) retStyle['--t4-tw-txt--l'] = styleTextColor;
    if(styleTextColorDark) retStyle['--t4-tw-txt--d'] = styleTextColorDark;
    if(styleTextMutedColor) retStyle['--t4-tw-muted--l'] = styleTextMutedColor;
    if(styleTextMutedColorDark) retStyle['--t4-tw-muted--d'] = styleTextMutedColorDark;
    if(styleBgColor) retStyle['--t4-tw-bg--l'] = styleBgColor;
    if(styleBgColorDark) retStyle['--t4-tw-bg--d'] = styleBgColorDark;
    if(styleLinkColor) retStyle['--t4-tw-link--l'] = styleLinkColor;
    if(styleLinkColorDark) retStyle['--t4-tw-link--d'] = styleLinkColorDark;
    if(styleSurfaceColor) retStyle['--t4-tw-sfc--l'] = styleSurfaceColor;
    if(styleSurfaceColorDark) retStyle['--t4-tw-sfc--d'] = styleSurfaceColorDark;
    return retStyle
  }, [styleTextColor, styleTextColorDark, styleTextMutedColor, styleTextMutedColorDark, styleBgColor, styleBgColorDark, styleLinkColor, styleLinkColorDark, styleSurfaceColor, styleSurfaceColorDark])

  const className = classNames(
    "tik4-tw",
    {
      "tik4-tw--on-auto tik4--on-auto": colorScheme === "auto",
      "tik4-tw--on-light tik4--on-light": colorScheme === "light",
      "tik4-tw--on-dark tik4--on-dark": colorScheme === "dark",
      "tik4-tw--slider": layout === 'slider',
      "tik4-tw--list": layout === 'list',
      "tik4-tw--tl": layoutTimeline === 'on',
      "tik4-tw--tl tik4-tw--tl--t ": layoutTimeline === 'time',
      "tik4-tw--invert-time": invertTime,
      "tik4-tw--li-badge": layoutLiveIndicatorStyle === "badge",
      "tik4-tw--empty": numberOfEvents === 0
    },
    "tik4"
  )

  const wrapperClassName = classNames(
    "tik4",
    {
      "tik4--on-auto": colorScheme === "auto",
      "tik4--on-light": colorScheme === "light",
      "tik4--on-dark": colorScheme === "dark"
    }
  )

  const {ref: sizeRef, breakpoint: breakpointClassName} = useBreakpoints([
    ["", 460],
    ["tik4-tw--xs", 576],
    ["tik4-tw--xs tik4-tw--sm", 768],
    ["tik4-tw--xs tik4-tw--sm tik4-tw--md", 992],
    ["tik4-tw--xs tik4-tw--sm tik4-tw--md tik4-tw--lg", 1200],
    ["tik4-tw--xs tik4-tw--sm tik4-tw--md tik4-tw--lg tik4-tw--xl", Infinity]
  ]);

  const value = useMemo(() => ({
    className: wrapperClassName,
    style,
    layout
  }), [wrapperClassName, style, layout])

  return <ReactWrapperContext.Provider value={value}>
    <div ref={mergeRefs([ref,sizeRef])} className={`${className} ${breakpointClassName}`} style={style as any}>
      {css && <style dangerouslySetInnerHTML={{__html: css}} />}
      <div className="tik4-tw__container">
        {children}
      </div>
    </div>
  </ReactWrapperContext.Provider>
}

// TODO:Timo TODO:Matthias hab ich einfach nur von LiveblogEventRow.tsx kopiert
function mergeRefs<T = any>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}

export function useTeaserWrapperClassNames() {
  return useContext(ReactWrapperContext)
}

export function useTeaserWrapperLayout() {
  return useContext(ReactWrapperContext).layout
}

export const TeaserWrapper = forwardRef<HTMLDivElement, TeaserWrapperProps>(TeaserWrapperImp);
