import React from 'react'
import {ErrorBoundary} from "@webng/react-app-common";
import {ExternalUserProvider} from "@webng/comments";
import loadable from '@loadable/component'
import {CircleLoader} from "@webng/icons-common";
import {useLiveblogWrapperClassNames} from "../LiveblogWrapper";
import {TrackingFunction} from "../LiveblogRenderContext";

// React suspense does not work server side so we have to use loadable library instead
//const ChannelPreviewWrapper = React.lazy(() => import('./ChannelPreviewWrapper'));
// Will render the fallback server side which is good enough for us
const ChannelPreviewWrapper = loadable(() => import('./QueryClientWrapper').then(wrapper => wrapper.ChannelPreviewWrapper), {
  // Do not use CommentsContainer directly as this will break the lazy loading!
  fallback: <div className="tik4-content-block tik4-content-block--comments">
    <div className="tik4-comments">
      <div className="tik4-comments__loader">
        {/* {t('liveblog.comments.preview.loading')} */}
        <CircleLoader className="tik4-icon tik4-spin"/>
      </div>
    </div>
</div>
})

interface ChannelPreviewProps {
  ownerId: string
  channelId: string
  version: number
  position?: number
  onCommentManage?: (commentId: string) => void
  externalUserProvider?: ExternalUserProvider
  useCookies?: boolean
  trackingFunction?: TrackingFunction
}

export function ChannelPreview({ownerId, channelId, externalUserProvider, version, position, onCommentManage, useCookies, trackingFunction}: ChannelPreviewProps) {
  const {className, style} = useLiveblogWrapperClassNames()

  return <ErrorBoundary>
    {/*<React.Suspense fallback={fallback}>*/}
      <ChannelPreviewWrapper ownerId={ownerId}
                             channelId={channelId}
                             externalUserProvider={externalUserProvider}
                             version={version}
                             onCommentManage={onCommentManage}
                             position={position}
                             portalClassName={className}
                             portalStyle={style}
                             useCookies={useCookies}
                             trackingFunction={trackingFunction}
      />
    {/*</React.Suspense>*/}
  </ErrorBoundary>
}


