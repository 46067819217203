import React, { useCallback, useMemo, useState } from "react";
import { EmbedProviderProps } from "./EmbedProviders";
import { ensureScriptTag } from "./tagManager";
import { generateRandomId } from "@webng/uniqueid-generator";

interface AgnoplayConfiguration {
  videoId: string;
  brand: string;
  autoplay?: boolean;
  element: HTMLDivElement;
  kaching?: any;
}

let configQueue: AgnoplayConfiguration[] = []; // Queue for configurations
let isEnvironmentReady = false; // Tracks if AGNO and kaching are ready
type KachingInitStatusType = "none" | "pending" | "success" | "error";
let kachingInitStatus: KachingInitStatusType = "none";

const KachingEnabledHosts = ["nieuwsblad.be","gva.be","hbvl.be","standaard.be","aachener-zeitung.de","belfasttelegraph.co.uk","independent.ie","sundayworld.com","contacto.lu","luxtimes.lu","virgule.lu","wort.lu","dvhn.nl","frieschdagblad.nl","gooieneemlander.nl","haarlemsdagblad.nl","hoogeveenschecourant.nl","ijmuidercourant.nl","lc.nl","leidschdagblad.nl","limburger.nl","meppelercourant.nl","sikkom.nl","steenwijkercourant.nl","telegraaf.nl","noordhollandsdagblad.nl"];

function isMatchingDomain(hostname: string, domain: string): boolean {
  return hostname === domain || hostname.endsWith(`.${domain}`);
}

function checkKaching() {
  const hostname = window.location.hostname;
  const kachingEnabled =
    (window as any).AGNO_kachingEnabled ||
    KachingEnabledHosts.some(domain => isMatchingDomain(hostname, domain));
  const kachingAvailable = typeof (window as any).kaching !== "undefined";

  return [kachingEnabled, kachingAvailable];
}

function pollForKachingReady(timeout = 5000, interval = 100) {
  return new Promise<KachingInitStatusType>((resolve, reject) => {
    if (kachingInitStatus === "success") {
      resolve(kachingInitStatus);
      return;
    }
    if (kachingInitStatus === "pending") {
      resolve(kachingInitStatus);
      return;
    }
    if (kachingInitStatus === "error") {
      reject(new Error("KaChing failed to become ready."));
      return;
    }

    kachingInitStatus = "pending";

    const startTime = Date.now();

    const poll = () => {
      const [kachingEnabled, kachingAvailable] = checkKaching();
      if (!kachingEnabled || (kachingEnabled && kachingAvailable)) {
        kachingInitStatus = "success";
        resolve(kachingInitStatus); // Ready to initialize
        return;
      }

      if (Date.now() - startTime >= timeout) {
        kachingInitStatus = "error";
        reject(new Error("Timeout: KaChing did not become ready in time (timeout)."));
        return;
      }

      setTimeout(poll, interval);
    };

    poll();
  });
}

function processQueue() {
  configQueue.forEach((configuration) => {
    try {
      console.log(`Tickaroo - Initializing player for videoId: ${configuration.videoId}`);
      (window as any).AGNO.insertPlayer(
        {
          brand: configuration.brand,
          videoId: configuration.videoId,
          url: window.location.href,
          kaching: (window as any).kaching,
        },
        configuration.element
      );
    } catch (error) {
      console.warn("Error initializing AGNO player", error);
    }
  });
  configQueue = [];
}

function addToQueue(configuration: AgnoplayConfiguration) {
  configQueue.push(configuration);

  if (isEnvironmentReady) {
    processQueue();
  }
}

function ensureEnvironmentReady() {
  if (isEnvironmentReady) return;

  function onScriptLoad() {
    console.log("onscriptloaded")
    pollForKachingReady()
      .then((status) => {
        if(status !== "pending") {
          console.log(`Tickaroo -Kaching loading done (${status}).`);
          isEnvironmentReady = true;
          processQueue(); // Process any queued configurations
        }
      })
      .catch((error) => {
        console.warn(error.message);
        isEnvironmentReady = true;
        processQueue(); // Fallback: Process queued configurations even if KaChing failed
      });
  }

  if(typeof (window as any).AGNO !== 'undefined' && typeof (window as any).AGNO.insertPlayer !== 'undefined') {
    console.log("Tickaroo - AGNO script detected, initializing ...");
    onScriptLoad();
  } else {
    ensureScriptTag({
      src: "https://player.agnoplay.com/static/agnoplay/js/agnoplay.js",
      async: true,
      crossOrigin: "anonymous",
      onload: () => {
        console.log("Tickaroo - AGNO script loaded. Checking kaching status...");
        onScriptLoad();
      },
    });
  }
}

function parseOembedHtml(html: string): { videoId: string; brand: string; autoplay: boolean } | null {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const div = doc.querySelector("div");

    if (div && div.dataset) {
      const videoId = div.dataset.videoId || "";
      const brand = div.dataset.brand || "";
      const autoplay = div.dataset.autoplay === "true";

      return { videoId, brand, autoplay };
    }
  } catch (error) {
    console.warn("Error parsing oembed HTML", error);
  }
  return null;
}

export function Agnoplay({ oembed }: EmbedProviderProps) {
  const [id] = useState(generateRandomId("agno"));

  const oembedConfig = useMemo(() => {
    return parseOembedHtml(oembed.html || "");
  }, [oembed.html]);

  const ref = useCallback(
    (element: HTMLDivElement) => {
      if (!element || !oembedConfig) return;

      addToQueue({ ...oembedConfig, element });
      ensureEnvironmentReady();
    },
    [oembedConfig]
  );

  return <div id={`agnoplay-${oembedConfig?.videoId}-${id}`} ref={ref} />;
}
