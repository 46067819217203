import { supportedLocales } from '@webng-types/i18n';

function scoreLocale(locale: string, supportedLocale: string): number {
  if(locale === supportedLocale) {
    return 10;
  } else if(locale.startsWith(supportedLocale)) {
    return 5;
  } else if(locale.split("-")[0] === supportedLocale.split("-")[0]) {
    return 4;
  } else if(supportedLocale === 'en') {
    return 1;
  } else {
    return 0
  }
}

export function findClosestLanguage(locale: string): string {
  locale = locale.toLocaleLowerCase()
  return supportedLocales.map(supportedLocale => ({supportedLocale, score: scoreLocale(locale, supportedLocale)}))
    .sort((a, b) => b.score - a.score)[0]
    .supportedLocale;
}