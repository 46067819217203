import React, {useCallback} from "react";
import {IEvent, IGame, ITeamGameScoreboard} from "@webng-types/write-model";
import {useTranslation} from "react-i18next";
import {OnSummaryItemClickHandler} from "./Liveblog";

interface LiveblogGoalListItemProps {
  game: IGame
  event: IEvent
  incompleteFallback?: (player?: string, team?: string, time_of_play?: string) => React.ReactNode
  onClick?: OnSummaryItemClickHandler
}

export const LiveblogGameSummaryItem = React.forwardRef<HTMLHeadingElement, LiveblogGoalListItemProps>(function LiveblogGoalListItemImp({event, game, incompleteFallback, onClick}, ref) {
  const {t} = useTranslation("liveblog");

  const onClickCallback = useCallback(() => {
    onClick && onClick(event)
  }, [onClick, event])

  if(!game.scoreboard || game.scoreboard._type !== 'Tik::Model::Content::Scoreboard::TeamGameScoreboard') {
    return <></>;
  }

  const scoreboard = game.scoreboard as ITeamGameScoreboard;

  const player = event.sport_event?.players?.find(p => p.event_player_type === 'scorer')?.player
  const team = event.sport_event?.team
  const time_of_play = event.sport_event?.time_of_play

  if (team && time_of_play && player) {
    const isHome = scoreboard.home_team?.local_id === event.sport_event?.team?.local_id;
    const isAway = scoreboard.away_team?.local_id === event.sport_event?.team?.local_id;
    const isOwnGoal = event.sport_event?.event_type?.endsWith('.own-goal')

    // own goal considered
    const isHomeOg = (isOwnGoal && isAway) || (!isOwnGoal && isHome);
    const isAwayOg = (isOwnGoal && isHome) || (!isOwnGoal && isAway);

    return <div className={`tik4-gsg__i ${isHomeOg ? 'tik4-gsg__i--ht' : ''} ${isAwayOg ? 'tik4-gsg__i--at' : ''}`}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div className="tik4-gsg__p" ref={ref} onClick={onClickCallback} tabIndex={0} role="button">
        <div className="tik4-gsg__p__n" title={player ? player.name : undefined}>
          {player && player.name}
        </div>
        {isOwnGoal && <div className="tik4-gsg__p__og" title={t('liveblog.goal_list.own_goal.label.long')}>&nbsp;{t('liveblog.goal_list.own_goal.label.short')}</div>}
      </div>
      <div title={time_of_play} className="tik4-gsg__top">{time_of_play}</div>
    </div>
  } else if (incompleteFallback){
    // return <>{incompleteFallback( player && player.name, team && team.name, time_of_play)}</>
    return <>{incompleteFallback( player ? player.name : undefined, team ? team.name : undefined, time_of_play ? time_of_play : undefined)}</>
  } else {
    return <></>
  }


})
