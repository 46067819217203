import {useMemo} from "react";
import globals from "../../analytics/globals";
import {sendEvents} from "../../analytics/sendEvents";
import globalSettings from "../globalSettings";
import {useEnvironment} from "@webng/react-app-common";
import {LiveblogEvent} from "@webng/liveblog";

class LiveblogTeaserAgent {
  private readonly _id: string;
  private readonly _clientId: string;
  private readonly _useCookie: boolean;
  private _events: LiveblogEvent[] = []
  private _retention = 0

  private _sendDelay: number = 0;
  private _lastSendTime: number|undefined = undefined;
  private _retentionTimerStart: number|undefined = undefined;
  private _retentionLastPingTime: number|undefined = undefined;

  constructor(clientId: string, id: string, useCookie: boolean) {
    this._id = id;
    this._clientId = clientId;
    this._useCookie = useCookie;

    this.track = this.track.bind(this)
  }

  track(e: LiveblogEvent) {
    this._events.push(Object.assign({ts: new Date().getTime()}, e))
    this.sendNow()
  }

  sendNow() {
    if(this._events.length > 0 || this._retention > 0) {
      const g = globals(this._useCookie)

      const eventToSend = {
        id: this._id,
        v: 'tik4-teaser',
        d: g.uid,
        s: g.sid,
        ul: g.uaLocation,
        ur: g.uaReferrer,
        e: this._events,
        r: this._retention
      }

      const url = globalSettings.analyticsBaseUrl + "/api/collect/v7/collect-liveblog-teaser.json?client_id=" + encodeURIComponent(this._clientId);
      sendEvents(url, eventToSend)

      this._events = []
    }
  }
}


export function useLiveblogTeaserAnalyticsAgent(id: string, useCookie: boolean, disabled: boolean) {
  const {clientId} = useEnvironment()

  return useMemo(() => disabled ? null : new LiveblogTeaserAgent(clientId, id, useCookie), [clientId, id, useCookie, disabled])
}

