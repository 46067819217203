import {
  IAbstractMediaContent,
  IEvent,
  IImageMediaContent,
  IMultiMediaContentBlock,
  IVideoMediaContent
} from "@webng-types/write-model";
import {ImageMediaItemViewModel, MediaItemViewModel, VideoMediaItemViewModel} from "./ViewModel";
import {aspectWidthSizedImageUrl, exactSizeImageUrl, ReturnImage, thumbnailSource} from "./imageUrl";


function liveblogImageMediaItemConverter(event: IEvent,
                                         contentblock: IMultiMediaContentBlock,
                                         item: IImageMediaContent): ImageMediaItemViewModel {
  const media = event.media?.find(media => media.local_id === item.media_local_id);

  const tSource = thumbnailSource(media)

  const sourceWidth = tSource.width || 960;
  let previews = new Array<ReturnImage>();

  previews.push(aspectWidthSizedImageUrl(tSource, 320));

  for (const targetWidth of [420, 640, 960, 1250, 1600]) {
    if (sourceWidth > targetWidth * 1.1) { // only use a scaled version if picture is more than 10% bigger than the requested size, otherwise just use the original size
      previews.push(aspectWidthSizedImageUrl(tSource, targetWidth, {format: 'webp'}));
    } else if(tSource.width && tSource.height) {
      previews.push(exactSizeImageUrl(tSource.url, {width: tSource.width, height: tSource.height, fit: "null", format: 'webp'}));
      break;
    }
  }

  return {
    kind: "image",
    id: item.media_local_id!,
    title: item.title || "",
    credit: item.credit || "",
    contentWarning: item.content_warning,
    url: media?.url || "",
    originalWidth: tSource.width || 0,
    originalHeight: tSource.height || 0,
    previews: previews,
    media: media
  };
}

function liveblogVideoMediaItemConverter(event: IEvent,
                                         contentBlock: IMultiMediaContentBlock,
                                         item: IVideoMediaContent): VideoMediaItemViewModel {
  const media = event.media?.find(media => media.local_id === item.media_local_id);

  const tSource = thumbnailSource(media)

  return {
    kind: "video",
    id: item.media_local_id!,
    title: item.title || "",
    credit: item.credit || "",
    contentWarning: item.content_warning,
    originalWidth: tSource.width || 0,
    originalHeight: tSource.height || 0,
    duration: media?.duration_ms || 0,
    previews: [
      aspectWidthSizedImageUrl(tSource, 160, {format: 'webp'}),
      aspectWidthSizedImageUrl(tSource, 320, {format: 'webp'}),
      aspectWidthSizedImageUrl(tSource, 640, {format: 'webp'}),
      aspectWidthSizedImageUrl(tSource, 960, {format: 'webp'})
    ],
    media: media
  };
}

export function liveblogMediaItemConverter(event: IEvent,
                                           contentBlock: IMultiMediaContentBlock,
                                           item: IAbstractMediaContent): MediaItemViewModel {
  switch(item._type) {
    case "Tik::Model::Content::ImageMediaContent":
      return liveblogImageMediaItemConverter(event, contentBlock, item);
    case "Tik::Model::Content::VideoMediaContent":
      return liveblogVideoMediaItemConverter(event, contentBlock, item);
    default:
      throw new Error("Unknown content type: " + item._type)
  }
}
