import React from "react";
import {AbstractLiveblogElement} from "./AbstractLiveblogElement";
import {PropsParser} from "@webng/validations";
import {
  LiveblogTeaserThemeConfiguration,
  liveblogTeaserThemeConfigurationPropsParser,
  SharedThemeConfiguration,
  sharedThemeConfigurationPropsParser
} from "@webng-types/embedjs";
import {
  LiveblogTeaserWidgetApi,
  LiveblogTeaserWidgetProps,
  LiveblogTeaserWidgetQueryProps,
  liveblogTeaserWidgetQueryPropsParser,
  LiveblogTeaserWidgetTagProps,
  liveblogTeaserWidgetTagPropsParser
} from "./liveblog-teaser/LiveblogTeaserWidgetProps";
import {LiveblogWidgetQueryProps} from "./liveblog/LiveblogWidgetProps";
import {LiveblogTeaserWidget} from "./liveblog-teaser/LiveblogTeaserWidget";

const propsParser: PropsParser<LiveblogTeaserWidgetTagProps> = {
  ...liveblogTeaserWidgetTagPropsParser,
};

export class TickarooLiveblogTeaserElement extends AbstractLiveblogElement<LiveblogTeaserThemeConfiguration, LiveblogTeaserWidgetTagProps, LiveblogTeaserWidgetQueryProps, LiveblogTeaserWidgetApi> {
  static get observedAttributes() {
    return [
      ...Object.keys(propsParser).map((x) => x.toLowerCase()),
      ...Object.keys(liveblogTeaserThemeConfigurationPropsParser).map((x) =>
        x.toLowerCase()
      ),
      ...Object.keys(sharedThemeConfigurationPropsParser).map((x) =>
        x.toLowerCase()
      ),
    ];
  }

  constructor() {
    super({...liveblogTeaserThemeConfigurationPropsParser, ...sharedThemeConfigurationPropsParser}, liveblogTeaserWidgetTagPropsParser, liveblogTeaserWidgetQueryPropsParser);
  }

  useHydrate(props: {
    tagProps: LiveblogTeaserWidgetTagProps;
    queryProps: LiveblogTeaserWidgetQueryProps;
    overrideConfigurationProps: Partial<LiveblogTeaserThemeConfiguration & SharedThemeConfiguration>
  }): boolean {
    return false;
  }

  renderImp(ref: (r: LiveblogTeaserWidgetApi) => void, props: {
    tagProps: LiveblogTeaserWidgetProps;
    queryProps: LiveblogWidgetQueryProps;
    overrideConfigurationProps: Partial<LiveblogTeaserThemeConfiguration & SharedThemeConfiguration>
  }): React.ReactNode {
    const finalProps = Object.assign({}, props.tagProps, props.queryProps, {overrideConfiguration: props.overrideConfigurationProps});
    return <LiveblogTeaserWidget ref={ref} {...finalProps} />;
  }
}
