import React, {ReactNode, useCallback, useMemo, useState} from "react";
import {ImageMediaItemViewModel, MediaItemViewModel, VideoMediaItemViewModel} from "../converter/ViewModel";
import {ApiImage, expandImageUrl} from "../ApiImage";
import {useEnvironment} from "@webng/react-app-common";
import {VideoFrameOptions, videoFrameUrl} from "../converter/imageUrl";
import {Eye, Film, Frown} from "react-feather";
import {useTranslation} from 'react-i18next';
import classNames from "classnames";
import {useMediaExtraComponent} from "../LiveblogRenderContext";
import {IMedia} from "@webng-types/write-model";
import useDebouncedResizeObserver from "@webng/react-app-common/src/utils/useDebouncedResizeObserver";

export type OnMediaClickHandler = (media: IMedia) => void

function unknownKind(block: never): never {
  throw new Error("Unknown Media Item type " + block);
}


interface MediaWithContentWarningViewProps {
  className?: string,
  item: ImageMediaItemViewModel | VideoMediaItemViewModel
  children: ReactNode
}

function MediaWithContentWarningView({item, children}: MediaWithContentWarningViewProps) {
  const {t} = useTranslation('liveblog')
  const [liftContentWarning, setLiftContentWarning] = useState(false)

  const onLiftContentWarning = useCallback(() => {
    setLiftContentWarning(true)
  }, [])

  if(typeof item.contentWarning === 'string' && !liftContentWarning) {
    return <ImageMediaItemView item={item} warning={true}>
      <div className="tik4-media-image__warning">
        <div className="tik4-media-image__warning__content">
          <button className="tik4-media-image__warning__button tik4-button tik4-button--txt--l" onClick={onLiftContentWarning} type="button"><Eye className="tik4-icon tik4-icon--mr" />{t('liveblog.media.content_warning.button')}</button>
          <span className="tik4-media-image__warning__label">{item.contentWarning || t('liveblog.media.content_warning.default_label')}</span>
        </div>
        <div className="tik4-media-image__warning__overlay"></div>
      </div>
    </ImageMediaItemView>

  } else {
    return <React.Fragment>{children}</React.Fragment>
  }
}


interface MediaItemContainerProps {
  className?: string,
  item: ImageMediaItemViewModel | VideoMediaItemViewModel
  children: ReactNode
}
function MediaItemContainer({className, item, children }:MediaItemContainerProps) {
  const MediaExtraComponent = useMediaExtraComponent();

  const clsName = classNames('tik4-media-item', { relative: !!MediaExtraComponent} ,className);
  return <div className={clsName}>
    {children}

    {MediaExtraComponent && <MediaExtraComponent media={item.media} />}
  </div>
}

interface ImageMediaItemViewProps {
  item: ImageMediaItemViewModel | VideoMediaItemViewModel,
  warning?: boolean,
  children?: ReactNode
  onMediaClick?: OnMediaClickHandler
}

function ImageMediaItemView({item, warning, children, onMediaClick} : ImageMediaItemViewProps) {
  const maxPreview = item.previews[item.previews.length - 1];
  const srcSet = item.previews.map(p => `${p.url} ${p.width}w`).join(", ");
  const {ref, width} = useDebouncedResizeObserver(250);

  const onMediaItemClick = useMemo(() => {
    if(onMediaClick && item.media) {
      return () => {
        if(item.media) {
          onMediaClick(item.media)
        }
      }
    } else {
      return undefined
    }
  }, [onMediaClick, item.media])

  return <MediaItemContainer className="tik4-media-item--image" item={item}>

    <div className={`tik4-media-image ${warning ? 'tik4-media-image--has-warning' : ''}`}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events*/}
      <div ref={ref} className={`tik4-media-image__img-wrapper ${onMediaItemClick ? 'tik4-media-image__img-wrapper--slideshow-trigger' : ''}`} onClick={onMediaItemClick} role={onMediaItemClick ? 'button' : undefined} style={{
        "--t4-media-img-width": maxPreview.width,
        "--t4-media-img-height": maxPreview.height} as React.CSSProperties}>
        <ApiImage
            className={"tik4-media-image__img"}
            sizes={`(max-width: 100vw) ${Math.min(width || 0, maxPreview.width)}px, 100vw`}
            srcSet={srcSet}
            alt={item.title}
            title={item.title}
            width={maxPreview.width}
            height={maxPreview.height}
        />
        {/* TODO:Timo blur one day :D */}
        {/* <img
            className="tik4-media-image__blur"
            src={item.previews[0]}
            alt={item.title}
            title={item.title}
            width={item.originalWidth}
            height={item.originalHeight}
        /> */}
        {children}
      </div>
      <MediaItemTitleCredit item={item} />
    </div>

  </MediaItemContainer>
}


function UnfinishedVideoMediaItemView({item} : {item: VideoMediaItemViewModel}) {
  const {t} = useTranslation("liveblog");

  return <ImageMediaItemView item={item}>
    <div className="absolute z-10 left-0 top-0 right-0 bottom-0 flex items-center justify-center text-center overlay-bg-dark text-white flex-col">
      <Film />
      <span className="mt-2 text-xs">{t('liveblog.media.processing_video')}</span>
    </div>
  </ImageMediaItemView>
}

function FinishedVideoMediaItemView({gameId, eventId, item} : {gameId: string, eventId: string, item: VideoMediaItemViewModel}) {
  const env = useEnvironment();
  const opts: VideoFrameOptions = {
    notrack: "true",
  }
  if(env.accessToken){
    opts.access_token = env.accessToken;
  } else if(env.clientId){
    opts.client_id = env.clientId;
  }
  const frameUrl = videoFrameUrl(gameId, eventId, item.id, opts)
  const fullFrameUrl = expandImageUrl(env.apiHost, frameUrl)

  const ratio = item.originalHeight/item.originalWidth;
  const cssHeight = `min(${ratio * 100}%, ${item.originalHeight}px,var(--t4-media-max-h))`

  return <MediaItemContainer className="tik4-media-item--video" item={item}>
    <div className="tik4-media-video">
      <div className="tik4-media-video__video-wrapper" style={{paddingTop: cssHeight, position: "relative", width: `calc(${cssHeight} * ${1/ratio})`, maxWidth: "100%"}}>
        <iframe src={fullFrameUrl} title={item.title} width="100%" height="100%" allowFullScreen={true} style={{position: "absolute", top: 0, left: 0}} />
      </div>
      <MediaItemTitleCredit item={item} />
    </div>

  </MediaItemContainer>

}

function MediaItemTitleCredit({item} : {item: ImageMediaItemViewModel | VideoMediaItemViewModel}) {
  if (item.title || item.credit) {
    return <div className="tik4-media-body">
      {item.title && <span className="tik4-media-body__title">{item.title}</span>}
      {/* Add punctuation if not in title and credit available */}
      {item.title && !item.title.match(/[.;!?,:]$/) && item.credit && <span className="tik4-media-body__punctuation">.</span>}
      {/* Add space */}
      {item.title && item.credit && ' '}
      {item.credit && <span className="tik4-media-body__credit">{item.credit}</span>}
    </div>
  } else {
    return null
  }
}

function VideoMediaItemView({gameId, eventId, item} : {gameId: string, eventId: string, item: VideoMediaItemViewModel}) {
  if(!item.media?.needs_upload_from_device) {
    return <MediaWithContentWarningView item={item}>
      <FinishedVideoMediaItemView gameId={gameId} eventId={eventId} item={item} />
    </MediaWithContentWarningView>
  } else {
    return <UnfinishedVideoMediaItemView item={item}/>
  }
}

function MediaErrorMessage({asset}: {asset: string|undefined}) {
  const {t} = useTranslation("liveblog");

  if(asset?.startsWith('error:ImageNotSupported')) {
    return <>{t('liveblog.media.error.unsupported_media_format')}</>
  } else if(asset && asset.startsWith('error')) {
    return <>{t('liveblog.media.error.generic', {code: asset})}</>
  } else {
    return <>{t('liveblog.media.error.generic', {code: 'unknown'})}</>
  }
}

interface MediaItemViewProps {
  gameId: string
  eventId: string
  item: MediaItemViewModel
  onMediaClick?: OnMediaClickHandler
}

export function MediaItemView({gameId, eventId, item, onMediaClick} : MediaItemViewProps) {
  if(item.media?.needs_upload_from_device !== 'promote_failed') {
    switch (item.kind) {
      case "image":
        return <MediaWithContentWarningView item={item}>
          <ImageMediaItemView item={item} onMediaClick={onMediaClick}/>
        </MediaWithContentWarningView>
      case "video":
        return <VideoMediaItemView gameId={gameId} eventId={eventId} item={item}/>
      default:
        return unknownKind(item);
    }
  } else {
    return <div className="tik4-media-item-error">
      <div className="tik4-media-item-error__container">
        <Frown className="tik4-media-item-error__icon tik4-icon" />
        <span className="tik4-media-item-error__text"><MediaErrorMessage asset={item.media?.asset} /></span>
      </div>
    </div>
  }
}
