import {EnvironmentProvider, ErrorBoundary, useApi} from "@webng/react-app-common";
import globalSettings from "../globalSettings";
import React, {ForwardedRef, forwardRef, useEffect, useMemo, useState} from "react";
import {
  LiveblogTeaserThemeConfiguration,
  liveblogTeaserThemeConfigurationDefaultValues,
  liveblogTeaserThemeConfigurationPropsParser,
  LiveblogTeaserWidgetInitialData,
  SharedThemeConfiguration,
  sharedThemeConfigurationDefaultValues,
  sharedThemeConfigurationPropsParser
} from "@webng-types/embedjs";
import {parseObject, parsePartialObject} from "@webng/validations";
import {LoadErrorView} from "../LoadErrorView";
import {LiveblogTeaserWidgetApi, LiveblogTeaserWidgetProps} from "./LiveblogTeaserWidgetProps";
import {ApiClient, isApiResponseError} from "@webng/tickaroo-api";
import {LiveblogTeaserWidgetCore} from "./LiveblogTeaserWidgetCore";
import {I18nWrapper} from "../I18nWrapper";

interface LiveblogTeaserControllerProps extends LiveblogTeaserWidgetProps {
  overrideConfiguration: Partial<LiveblogTeaserThemeConfiguration & SharedThemeConfiguration>
}

function LiveblogTeaserControllerImp({ clientId, themeId, liveblogId, overrideConfiguration, disableTracking, liveblogUrl, title }: LiveblogTeaserControllerProps, ref: ForwardedRef<LiveblogTeaserWidgetApi>) {
  const apiClient = useApi()

  const [data, setData] = useState<LiveblogTeaserWidgetInitialData>()
  const [error, setError] = useState<number|undefined>(undefined)

  useEffect(() => {
    const query: any = {client_id: clientId, themeId, liveblogId}

    if(!liveblogUrl) {
      query["includeLiveblogUrl"] = window.location.origin
    }

    apiClient.getJson(ApiClient.API_EMBED_V4_LIVEBLOG_TEASER, query)
    .then(setData)
    .catch(e => {
      if(isApiResponseError(e)) {
        setError(e.status)
      } else {
        setError(0)
      }
    })
  }, [apiClient, clientId, themeId, liveblogId, liveblogUrl])

  const finalConfiguration: LiveblogTeaserThemeConfiguration & SharedThemeConfiguration | undefined = useMemo(() => {
    if (data?.configuration) {
      const parsedConfiguration = parseObject({...liveblogTeaserThemeConfigurationPropsParser, ...sharedThemeConfigurationPropsParser}, data.configuration)
      const parsedOverrideConfiguration = parsePartialObject({...liveblogTeaserThemeConfigurationPropsParser, ...sharedThemeConfigurationPropsParser}, overrideConfiguration)
      return Object.assign({}, liveblogTeaserThemeConfigurationDefaultValues, sharedThemeConfigurationDefaultValues, parsedConfiguration, parsedOverrideConfiguration)
    } else {
      return undefined
    }
  }, [data?.configuration, overrideConfiguration])

  if (error !== undefined) {
    return <LoadErrorView status={error} />
  } else if (finalConfiguration && data) {
    return <I18nWrapper locale={finalConfiguration.locale} gameShowResponse={data.gameShowResponse}>
      <LiveblogTeaserWidgetCore ref={ref}
                                     initialData={{gameShowResponse: data.gameShowResponse!, uniqueId: data.uniqueId!}}
                                     css={data.css || ""}
                                     liveblogId={liveblogId}
                                     disableTracking={disableTracking}
                                     liveblogUrl={liveblogUrl || data.liveblogUrl || ""}
                                     title={title}
                                     {...finalConfiguration} />
    </I18nWrapper>
  } else {
    return <React.Fragment />
  }
}


const LiveblogTeaserController = forwardRef(LiveblogTeaserControllerImp)

function LiveblogTeaserWidgetImp({clientId, ...props}: LiveblogTeaserWidgetProps, ref: ForwardedRef<LiveblogTeaserWidgetApi>) {
  return <React.StrictMode>
    <ErrorBoundary>
      <EnvironmentProvider apiHost={globalSettings.baseUrl} clientId={clientId}>
        <LiveblogTeaserController ref={ref} clientId={clientId} {...props} />
      </EnvironmentProvider>
    </ErrorBoundary>
  </React.StrictMode>
}


export const LiveblogTeaserWidget = forwardRef(LiveblogTeaserWidgetImp)
