export function computeFixedHeaderOffset() {
  if (document.elementsFromPoint) {
    const fixedHeader = document.elementsFromPoint(0, 0)
    .filter(e => {
      const position = window.getComputedStyle(e).position;
      return position === 'fixed' || position === 'sticky'
    })
    .map(e => e.getBoundingClientRect().height)
    .filter(h => h > 0 && h < 250) //sanity check
    .reduce((a, b) => Math.max(a, b), 0);

    if (fixedHeader !== 0) {
      return fixedHeader
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}
