import React, {useRef} from "react";


export type FeatureFlag = "enableComments" | "websocketConnection" | "enableQuotes" | "enableOnDemand" | "enableStatistics" | "enableSportEvents" | "enableScoreboards" | "enableAlphaSportstypes" | "enableNewSettings" | "enableSportTiming" | "enableAIExperiments" | "enableSyndication" | "enablePolls" | "enableSportsData" | "enableSportsDataStreamEdit" | "enableSportsDataCreateUI" | "enableFoldOut";
export type FeatureFlags = Record<FeatureFlag, boolean|undefined>

export interface Environment {
  readonly clientId: string
  readonly accessToken?: string
  readonly logout: () => void
  readonly invalidateAccessToken: () => void
  readonly apiHost: string
  readonly featureFlags: FeatureFlags
}

const EnvironmentContext = React.createContext<Environment | undefined>(undefined);

interface EnvironmentProviderProps {
  children: React.ReactNode
  clientId: string
  apiHost?: string
  accessToken?: string
  featureFlags?: FeatureFlags
  invalidateAccessToken?: () => void
}


export function EnvironmentProvider({children, clientId, accessToken, apiHost, featureFlags}: EnvironmentProviderProps) {
  const logoutFormRef = useRef<HTMLFormElement>(null);

  const value = React.useMemo<Environment>(() => ({
    clientId,
    accessToken,
    apiHost: apiHost || "",
    logout: () => {
      if(logoutFormRef.current) {
        logoutFormRef.current.submit();
      }
    },
    invalidateAccessToken: () => {

    },
    featureFlags: Object.assign({
      enableComments: false
    }, featureFlags)
  }), [clientId,  accessToken, apiHost, featureFlags])

  return <EnvironmentContext.Provider value={ value }>
    {children}
  </EnvironmentContext.Provider>
}

export function useEnvironment(): Environment {
  const env = React.useContext(EnvironmentContext);
  if(env) {
    return env;
  } else {
    throw new Error("Environment not initialized!");
  }
}

export function useFeatureFlag(flag: FeatureFlag): boolean {
  const env = useEnvironment();
  return !!env.featureFlags[flag];
}
