import {useEffect, useMemo} from "react";
import globals from "../../analytics/globals";
import {sendEvents} from "../../analytics/sendEvents";
import globalSettings from "../globalSettings";
import {useEnvironment} from "@webng/react-app-common";
import {LiveblogEvent} from "@webng/liveblog";

const INITIAL_SEND_DELAY = 2000
const PING_TIME = 1000; // Target ping time
const SEND_DELAY = 4000
const SEND_DELAY_MULTIPLIER=2
const MAX_SEND_DELAY = 30000
const SIGNIFICANT_TIME_CHANGE = 60000; // If real ping time is > then its probably bogus

class LiveblogAgent {
  private readonly _id: string;
  private readonly _clientId: string;
  private readonly _useCookie: boolean;
  private _events: LiveblogEvent[] = []
  private _mounted: boolean = false
  private _retention = 0
  private _pingInterval: number|undefined = undefined;

  private _sendDelay: number = 0;
  private _lastSendTime: number|undefined = undefined;
  private _retentionTimerStart: number|undefined = undefined;
  private _retentionLastPingTime: number|undefined = undefined;

  constructor(clientId: string, id: string, useCookie: boolean) {
    this._id = id;
    this._clientId = clientId;
    this._useCookie = useCookie;

    this._lastSendTime = new Date().getTime()
    this._sendDelay = INITIAL_SEND_DELAY

    this.ping = this.ping.bind(this)
    this.track = this.track.bind(this)
    this.onPageHide = this.onPageHide.bind(this)
    this.onVisibilityChanged = this.onVisibilityChanged.bind(this)
    this.effectCallback = this.effectCallback.bind(this)
  }

  track(e: LiveblogEvent) {
    this._events.push(Object.assign({ts: new Date().getTime()}, e))
    this.ping()
  }

  onPageHide() {
    this.sendNow()
  }

  onVisibilityChanged() {
    if(document.visibilityState === 'hidden') {
      this.sendNow()
    } else {
      this.startRetentionTimer();
    }
  }

  startRetentionTimer(restart?: boolean) {
    const now = new Date().getTime();

    if (restart || !this._retentionTimerStart) {
      this._retentionTimerStart = now;
      this._retentionLastPingTime = now;
    }
  }

  ping() {
    const now = new Date().getTime();

    if(this._retentionTimerStart) {
      if (this._retentionLastPingTime && this._retentionLastPingTime > 0) { // was visible last time
        if (now - this._retentionLastPingTime > SIGNIFICANT_TIME_CHANGE) {
          // became invisible some time ago and we didnt notice. maybe visible again
          this.turnoverRetentionTimer(this._retentionLastPingTime);
        }
      }

      this._retentionLastPingTime = now;
    }

    if(this._events.length > 0) {
      if(!this._lastSendTime || now - this._lastSendTime >= this._sendDelay) {
        this.sendNow();

        // Make send delays bigger and bigger for each send as they are less relevant up to SIGNIFICANT_TIME_CHANGE
        if(this._sendDelay < SEND_DELAY) {
          this._sendDelay = SEND_DELAY;
        } else if(this._sendDelay < MAX_SEND_DELAY) {
          this._sendDelay *= SEND_DELAY_MULTIPLIER;
        }
      }
    } else {
      this._lastSendTime = now;
    }
  }

  turnoverRetentionTimer(now?: number) {
    if(this._retentionTimerStart) {
      now ||= new Date().getTime()
      const retentionMs = now - this._retentionTimerStart;
      this._retentionTimerStart = undefined
      this._retention += retentionMs;

      if (document.visibilityState === 'visible') {
        this.startRetentionTimer(true)
      }
    }
  }

  sendNow() {
    this.turnoverRetentionTimer();

    if(this._events.length > 0 || this._retention > 0) {
      const g = globals(this._useCookie)

      const eventToSend = {
        id: this._id,
        v: 'tik4',
        d: g.uid,
        s: g.sid,
        ul: g.uaLocation,
        ur: g.uaReferrer,
        e: this._events,
        r: this._retention
      }

      const url = globalSettings.analyticsBaseUrl + "/api/collect/v7/collect-liveblog.json?client_id=" + encodeURIComponent(this._clientId);
      sendEvents(url, eventToSend)

      this._lastSendTime = new Date().getTime();
      this._events = []
      this._retention = 0
    }
  }

  onMount() {
    window.addEventListener('pagehide', this.onPageHide, {capture: true})
    window.addEventListener('visibilitychange', this.onVisibilityChanged, {capture: true})
    this._pingInterval = window.setInterval(this.ping, PING_TIME)

    this.startRetentionTimer();
  }

  onUnmount() {
    window.removeEventListener('pagehide', this.onPageHide, {capture: true})
    window.removeEventListener('visibilitychange', this.onVisibilityChanged, {capture: true})
    if(this._pingInterval) {
      clearInterval(this._pingInterval)
    }

    this.sendNow()
  }

  effectCallback() {
    if(!this._mounted) {
      this._mounted = true
      this.onMount();
    }
    return () => {
      if(this._mounted) {
        this._mounted = false
        this.onUnmount()
      }
    }
  }
}


export function useLiveblogAnalyticsAgent(id: string, useCookie: boolean, disabled: boolean) {
  const {clientId} = useEnvironment()

  const agent = useMemo(() => disabled ? null : new LiveblogAgent(clientId, id, useCookie), [clientId, id, useCookie, disabled])

  useEffect(() => {
    if(agent){
      agent.effectCallback()
    }
  }, [agent])

  return agent
}

