import React, {ReactNode} from "react";
import {HeadlineContentBlockView} from "./HeadlineContentBlockView";
import {RichTextContentBlockView} from "./RichTextContentBlockView";
import {WebEmbedContentBlockView} from "./WebEmbedContentBlockView";
import {MultiMediaContentBlockView, MultiMediaContentBlockViewExportedProps} from "./MultiMediaContentBlockView";
import {QuoteContentBlockView} from "./QuoteContentBlockView";
import {IAbstractContentBlock, IEvent, IGame} from "@webng-types/write-model";
import {ScoreboardContentBlockView} from "./ScoreboardContentBlockView";
import {SportEventContentBlockView, SportEventContentBlockViewContainer} from "./SportEventContentBlockView";
import {PlayerCardContentBlockView} from "./PlayerCardContentBlockView";
import {EmojiContentBlockView} from "./EmojiContentBlockView";
import {useExternalUserProvider, useUseFunctionalCookies} from "../LiveblogRenderContext";
import {SportEventContentView} from "./SportEventContentView";
import {EventExtensionRenderer} from "../extensions/EventExtensionRenderer";
import {ChannelPreview} from "../chunks/Comments";
import {EventExtension} from "../extensions/Extension";
import classnames from "classnames";
import { PollContentBlock } from "../chunks/PollContentBlock";

interface ContentBlockViewProps extends MultiMediaContentBlockViewExportedProps {
  game: IGame
  event: IEvent
  block: IAbstractContentBlock
  position?: number
  isPreview?: boolean
}

// function unknownKind(block: never): never {
//   throw new Error("Unknown Content Block type " + block);
// }


function ContentBlockSwitcherView({block, position, isPreview, event, ...props} : ContentBlockViewProps) {
  switch(block._type) {
    case "Tik::Model::Content::HeadlineContentBlock":
      return <ContentBlockWrapper event={event} classPostfix="headline" position={position}>
        <HeadlineContentBlockView block={block} {...props} />
      </ContentBlockWrapper>
    case "Tik::Model::Content::RichTextContentBlock":
      return <ContentBlockWrapper event={event} classPostfix="rich-text" position={position}>
        <RichTextContentBlockView block={block} {...props} />
      </ContentBlockWrapper>
    case "Tik::Model::Content::WebEmbedContentBlock":
      return <ContentBlockWrapper event={event} classPostfix="web-embed" position={position}>
        <WebEmbedContentBlockView block={block} isPreview={isPreview} {...props} />
      </ContentBlockWrapper>
    case "Tik::Model::Content::MultiMediaContentBlock":
      return <ContentBlockWrapper event={event} classPostfix="multi-media" position={position}>
        <MultiMediaContentBlockView event={event} block={block} {...props} />
      </ContentBlockWrapper>
    case "Tik::Model::Content::QuoteContentBlock":
      return <ContentBlockWrapper event={event} classPostfix="quote" position={position}>
        <QuoteContentBlockView block={block} {...props} />
      </ContentBlockWrapper>
    case "Tik::Model::Content::SportEventContentBlock":
      return <ContentBlockWrapper event={event} classPostfix="sport-event" classPostfixAddition={block.event_type?.replace('.', '-')} position={position}>
        <SportEventContentBlockView block={block} {...props} />
      </ContentBlockWrapper>
    case "Tik::Model::Content::ScoreboardContentBlock":
      return <ContentBlockWrapper event={event} classPostfix="scoreboard" position={position}>
        <ScoreboardContentBlockView block={block} {...props} />
      </ContentBlockWrapper>
    case "Tik::Model::Content::AbstractContentBlock":
      return <ContentBlockWrapper event={event} classPostfix="none" position={position}>
        <React.Fragment></React.Fragment>
      </ContentBlockWrapper>
    case "Tik::Model::Content::PlayerCardContentBlock":
      return <ContentBlockWrapper event={event} classPostfix="none" position={position}>
        <PlayerCardContentBlockView block={block} {...props} />
      </ContentBlockWrapper>
    case "Tik::Model::Content::EmojiContentBlock":
      return <ContentBlockWrapper event={event} classPostfix="emoji" position={position}>
        <EmojiContentBlockView block={block} {...props} />
      </ContentBlockWrapper>
    case "Tik::Model::Content::PollContentBlock":
      return <ContentBlockWrapper event={event} classPostfix="poll" position={position}>
        <PollContentBlock event={event} block={block} isPreview={isPreview} {...props} />
      </ContentBlockWrapper>
    default:
      return <React.Fragment></React.Fragment>;
      // return unknownKind(block)
  }
}

function ContentBlockWrapper({children, classPostfix, classPostfixAddition, position, event} : {children: ReactNode, classPostfix: string, classPostfixAddition?: string, position?: number, event: IEvent}) {
  const classNames = classnames('tik4-content-block',
    {
      [`tik4-content-block--${classPostfix}`]: classPostfix,
      [`tik4-content-block--${classPostfix}--${classPostfixAddition}`]: classPostfixAddition,
      [`tik4-content-block--position-${position}`]: position,
      [`tik4-content-block--hlimi`]: (position && event.fold_out_index && (position > event.fold_out_index)),

    }
  )
  return <div className={classNames}>
    {children}
  </div>
}

export interface ContentBlocksViewProps extends MultiMediaContentBlockViewExportedProps {
  game: IGame
  event: IEvent
  comments?: {
    version: number
    ownerId: string
    channelId: string
  }
  eventExtension?: EventExtension
  isPreview?: boolean
}

export function ContentBlocksView({event, comments, eventExtension, isPreview, ...props}: ContentBlocksViewProps) {
  const externalUserProvider = useExternalUserProvider()
  const useFunctionalCookies =  useUseFunctionalCookies()
  // const navigate = useNavigate();
  // const localGame = useLocalGame();

  // const handleCommentManage = useCallback(function handleCommentManage(commentId: string){
  //   navigate(commentRoute(commentId, localGame.local_id!));
  // }, [navigate, localGame.local_id])
  let i = 0

  return <>
    { event.sport_event && event.sport_event.event_type &&
      <div className={`tik4-content-block tik4-content-block--sport-event tik4-content-block--position-${++i}`}><SportEventContentBlockViewContainer><SportEventContentView block={event.sport_event} game={props.game} /></SportEventContentBlockViewContainer></div>
    }
    <EventExtensionRenderer event={event} Extension={eventExtension?.AboveContent} />
    {event.contents?.map((block) => {
      // filter empty rich text blocks
      if(block && block._type === "Tik::Model::Content::RichTextContentBlock" && !block.text) {
        return <></>;
      }
      return <ContentBlockSwitcherView event={event} block={block} key={block.local_id} position={++i}
                                isPreview={isPreview} {...props} />
    })}
    <EventExtensionRenderer event={event} Extension={eventExtension?.BelowContent} />
    {comments && <ChannelPreview ownerId={comments.ownerId} channelId={comments.channelId} externalUserProvider={externalUserProvider} version={comments.version} position={++i} useCookies={useFunctionalCookies} />}
    <EventExtensionRenderer event={event} Extension={eventExtension?.BelowComments} />
  </>
}
