import {computeFixedHeaderOffset} from "./computeFixedHeaderOffset";

export function scrollToElement(el: Element, options?: { topOffset?: number }) {
  // el.scrollIntoView({behavior: 'smooth', block: 'center'})
  if (el) {
    var eventClientRect = el.getBoundingClientRect();
    var top = window.scrollY + eventClientRect.top - (options?.topOffset || computeFixedHeaderOffset() || 0);
    if (window && typeof window.tickarooLiveblogScrollTo === 'function') {
      window.tickarooLiveblogScrollTo(top)
    } else {
      window.scrollTo({
        behavior: "smooth",
        top: top
      });
    }
  }
}
