import {LiveblogTeaserThemeConfiguration, SharedThemeConfiguration} from "@webng-types/embedjs";
import {parseBoolean, PropsParser, requireString} from "@webng/validations";
import {IGameShowResponse} from "@webng-types/write-model";

export interface LiveblogTeaserWidgetTagProps {
  liveblogId: string
  clientId: string
  css: string
  themeId: string
  // initialData: LiveblogWidgetInitialData
  // customerConsent: boolean
  disableTracking: boolean
  liveblogUrl: string
  title: string
}

export interface LiveblogTeaserWidgetQueryProps {
}

export interface LiveblogTeaserWidgetProps extends LiveblogTeaserWidgetTagProps { //, LiveblogWidgetQueryProps
  overrideConfiguration: Partial<LiveblogTeaserThemeConfiguration & SharedThemeConfiguration>
}


export const liveblogTeaserWidgetTagPropsParser: PropsParser<LiveblogTeaserWidgetTagProps> = {
  clientId: i => requireString("clientId", i),
  liveblogId: i => requireString("liveblogId", i),
  // initialData: i => parseJSON("initialData", i, {}),
  css: i => i || "",
  themeId: i => i || "",
  // customerConsent: i => parseBoolean('customerConsent', i, false),
  disableTracking: i => parseBoolean('disableTracking', i, false),
  liveblogUrl: i => i || "",
  title: i => i || "",
}

export const liveblogTeaserWidgetQueryPropsParser: PropsParser<LiveblogTeaserWidgetQueryProps> = {
}


export interface LiveblogTeaserWidgetApi {

}


export interface LiveblogTeaserCoreWidgetTagProps extends LiveblogTeaserThemeConfiguration, SharedThemeConfiguration {
  liveblogId: string
  initialData: {gameShowResponse: IGameShowResponse, uniqueId: string}
  css: string
  disableTracking: boolean
  liveblogUrl: string
  title: string
}


export interface LiveblogTeaserCoreWidgetProps extends LiveblogTeaserCoreWidgetTagProps {
}
