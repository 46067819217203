import React, {createContext, ForwardedRef, forwardRef, ReactNode, useContext, useMemo} from "react";
import classNames from "classnames";
import {EventSharingType} from "./LiveblogRenderContext";
import useBreakpoints from "@webng/react-app-common/src/utils/useBreakpoints";
import {ColorScheme, LayoutAuthorPosition, LayoutEventTagPosition, LayoutType} from "./types";

interface LiveblogWrapperProps {
  liveblogId: string
  sharing: EventSharingType
  invertStickyEvents: boolean
  invertHighlightEvents: boolean
  invertSummaryEvents: boolean
  invertTime: boolean
  colorScheme: ColorScheme
  layout: LayoutType
  layoutAuthorPosition: LayoutAuthorPosition
  layoutEventTagPosition: LayoutEventTagPosition
  stylePrimaryColor: string
  stylePrimaryColorDark: string
  styleSecondaryColor: string
  styleSecondaryColorDark: string
  styleTextColor: string
  styleTextColorDark: string
  styleTextMutedColor: string
  styleTextMutedColorDark: string
  styleBgColor: string
  styleBgColorDark: string
  styleEventHighlightBgColor: string
  styleEventHighlightBgColorDark: string
  styleEventStickyBgColor: string
  styleEventStickyBgColorDark: string
  styleEventSummaryBgColor: string
  styleEventSummaryBgColorDark: string
  styleUseCards: boolean
  headerOffset?: number
  css?: string
  children: ReactNode
}

interface CssVariables {
  '--t4-primary--l'?: string
  '--t4-primary--d'?: string
  '--t4-secondary--l'?: string
  '--t4-secondary--d'?: string
  '--t4-txt--l'?: string
  '--t4-txt--d'?: string
  '--t4-muted--l'?: string
  '--t4-muted--d'?: string
  '--t4-bg--l'?: string
  '--t4-bg--d'?: string
  '--t4-ev-h-bg--l'?: string
  '--t4-ev-h-bg--d'?: string
  '--t4-ev-p-bg--l'?: string
  '--t4-ev-p-bg--d'?: string
  '--t4-ev-s-bg--l'?: string
  '--t4-ev-s-bg--d'?: string
  '--t4-pu-t'?: string
}

interface ReactWrapperContextType {
  className: string,
  style: CssVariables
  layout: LayoutType
}


const ReactWrapperContext = createContext<ReactWrapperContextType>({className: "", style: {}, layout: 'default'})

export function LiveblogWrapperImp({liveblogId, sharing, invertHighlightEvents, invertStickyEvents, invertSummaryEvents, invertTime, children, colorScheme, layout, layoutAuthorPosition, layoutEventTagPosition, stylePrimaryColor, styleSecondaryColor, stylePrimaryColorDark, styleSecondaryColorDark, styleTextColor, styleTextColorDark, styleUseCards, styleTextMutedColor, styleTextMutedColorDark, styleBgColor, styleBgColorDark, styleEventHighlightBgColor, styleEventHighlightBgColorDark, styleEventStickyBgColor, styleEventStickyBgColorDark, styleEventSummaryBgColor, styleEventSummaryBgColorDark, headerOffset, css} : LiveblogWrapperProps, ref: ForwardedRef<HTMLDivElement>) {

  const style = useMemo(() => {
    let retStyle: CssVariables = {}
    if(stylePrimaryColor) retStyle['--t4-primary--l'] = stylePrimaryColor;
    if(stylePrimaryColorDark) retStyle['--t4-primary--d'] = stylePrimaryColorDark;
    if(styleSecondaryColor) retStyle['--t4-secondary--l'] = styleSecondaryColor;
    if(styleSecondaryColorDark) retStyle['--t4-secondary--d'] = styleSecondaryColorDark;
    if(styleTextColor) retStyle['--t4-txt--l'] = styleTextColor;
    if(styleTextColorDark) retStyle['--t4-txt--d'] = styleTextColorDark;
    if(styleTextMutedColor) retStyle['--t4-muted--l'] = styleTextMutedColor;
    if(styleTextMutedColorDark) retStyle['--t4-muted--d'] = styleTextMutedColorDark;
    if(styleBgColor) retStyle['--t4-bg--l'] = styleBgColor;
    if(styleBgColorDark) retStyle['--t4-bg--d'] = styleBgColorDark;
    if(styleEventHighlightBgColor) retStyle['--t4-ev-h-bg--l'] = styleEventHighlightBgColor;
    if(styleEventHighlightBgColorDark) retStyle['--t4-ev-h-bg--d'] = styleEventHighlightBgColorDark;
    if(styleEventStickyBgColor) retStyle['--t4-ev-p-bg--l'] = styleEventStickyBgColor;
    if(styleEventStickyBgColorDark) retStyle['--t4-ev-p-bg--d'] = styleEventStickyBgColorDark;
    if(styleEventSummaryBgColor) retStyle['--t4-ev-s-bg--l'] = styleEventSummaryBgColor;
    if(styleEventSummaryBgColorDark) retStyle['--t4-ev-s-bg--d'] = styleEventSummaryBgColorDark;
    if(headerOffset) retStyle['--t4-pu-t'] = `${headerOffset}px`;
    return retStyle
  }, [stylePrimaryColor, stylePrimaryColorDark, styleSecondaryColor, styleSecondaryColorDark, styleTextColor, styleTextColorDark, styleTextMutedColor, styleTextMutedColorDark, styleBgColor, styleBgColorDark, styleEventHighlightBgColor, styleEventHighlightBgColorDark, styleEventStickyBgColor, styleEventStickyBgColorDark, styleEventSummaryBgColor, styleEventSummaryBgColorDark, headerOffset])

  const className = classNames(
    "tik4-live",
    {
      "tik4-live--on-auto tik4--on-auto": colorScheme === "auto",
      "tik4-live--on-light tik4--on-light": colorScheme === "light",
      "tik4-live--on-dark tik4--on-dark": colorScheme === "dark",
      "tik4-live--invert-pin": invertStickyEvents,
      "tik4-live--invert-summary": invertSummaryEvents,
      "tik4-live--invert-highlight": invertHighlightEvents,
      "tik4-live--default": layout === 'default',
      "tik4-live--timeline": layout === 'timeline',
      "tik4-live--masonry": layout === 'masonry',
      "tik4-live--invert-time": invertTime,
      "tik4-live--sharing-expanded": sharing === "expanded",
      "tik4-live--sharing-compact": sharing === "compact",
      "tik4-live--cards": styleUseCards,
      "tik4-live--author-top": layoutAuthorPosition === "top",
      "tik4-live--author-bottom-left": layoutAuthorPosition === "bottomLeft",
      "tik4-live--author-bottom-right": layoutAuthorPosition === "bottomRight",
      "tik4-live--author-off": layoutAuthorPosition === "off",
      "tik4-live--event-tag-position-off": layoutEventTagPosition === "off",
      "tik4-live--event-tag-position-bottom": layoutEventTagPosition === "bottom",
      "tik4-live--event-tag-position-top": layoutEventTagPosition === "top",
    },
    "tik4"
  )

  const wrapperClassName = classNames(
    "tik4",
    {
      "tik4--on-auto": colorScheme === "auto",
      "tik4--on-light": colorScheme === "light",
      "tik4--on-dark": colorScheme === "dark"
    }
  )

  const {ref: sizeRef, breakpoint: breakpointClassName} = useBreakpoints([
    ["", 460],
    ["tik4-live--xs", 576],
    ["tik4-live--xs tik4-live--sm", 768],
    ["tik4-live--xs tik4-live--sm tik4-live--md", 992],
    ["tik4-live--xs tik4-live--sm tik4-live--md tik4-live--lg", 1200],
    ["tik4-live--xs tik4-live--sm tik4-live--md tik4-live--lg tik4-live--xl", Infinity]
  ]);

  const value = useMemo(() => ({
    className: wrapperClassName,
    style,
    layout
  }), [wrapperClassName, style, layout])

  return <ReactWrapperContext.Provider value={value}>
    <div ref={mergeRefs([ref,sizeRef])} className={`${className} ${breakpointClassName}`} style={style as any}>
      {css && <style dangerouslySetInnerHTML={{__html: css}} />}
      <div className="tik4-live__container">
        {children}
      </div>
    </div>
  </ReactWrapperContext.Provider>
}

// TODO:Timo TODO:Matthias hab ich einfach nur von LiveblogEventRow.tsx kopiert
function mergeRefs<T = any>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}

export function useLiveblogWrapperClassNames() {
  return useContext(ReactWrapperContext)
}

export function useLiveblogWrapperLayout() {
  return useContext(ReactWrapperContext).layout
}

export const LiveblogWrapper = forwardRef<HTMLDivElement, LiveblogWrapperProps>(LiveblogWrapperImp);
