import {
  AutomaticSummaryType,
  automaticSummaryTypes, ColorScheme, colorSchemeValues,
  CommentsMode,
  commentsModeValues,
  EventSharingType,
  eventSharingTypes, EventTagNavigationType, eventTagNavigationTypes,
  LayoutAuthorPosition,
  layoutAuthorPositionValues,
  LayoutEventTagPosition,
  layoutEventTagPositionValues,
  LayoutType,
  layoutValues,
  ProviderConstraints,
  ProviderConstraintValue,
  providerConstraintValues
} from "@webng/liveblog";
import {
  parseBoolean,
  parseJSON,
  parseNumber,
  parseStringEnum,
  parseStringFormat,
  PropsParser,
} from "@webng/validations";
import {IGameShowResponse} from "@webng-types/write-model";
import {colorRegex, sharedThemeConfigurationDefaultValues} from "./shared";

export const refreshValues = ["auto", "notify", "off"] as const
export type RefreshType = typeof refreshValues[number]

export const sortValues = ["asc", "desc"] as const
export type SortOrder = typeof sortValues[number]

export interface LiveblogThemeConfiguration {
  refresh: RefreshType
  sort: SortOrder
  limit: number
  deepLinkLimit: number,
  deepLinkDepth: number,
  webEmbedDefaultConstraint: ProviderConstraintValue
  webEmbedConstraints: ProviderConstraints
  useSlideshow: boolean
  automaticSummary: AutomaticSummaryType
  automaticSummaryHighlightsLimit: number
  eventTagNavigation: EventTagNavigationType
  sharing: EventSharingType
  layout: LayoutType
  layoutAuthorPosition: LayoutAuthorPosition
  layoutEventTagPosition: LayoutEventTagPosition
  styleUseCards: boolean
  styleInvertTime: boolean
  styleInvertHighlightEvents: boolean
  styleInvertStickyEvents: boolean
  styleInvertSummaryEvents: boolean
  stylePrimaryColor: string
  stylePrimaryColorDark: string
  styleSecondaryColor: string
  styleSecondaryColorDark: string
  styleTextColor: string
  styleTextColorDark: string
  styleTextMutedColor: string
  styleTextMutedColorDark: string
  styleBgColor: string
  styleBgColorDark: string
  styleEventHighlightBgColor: string
  styleEventHighlightBgColorDark: string
  styleEventStickyBgColor: string
  styleEventStickyBgColorDark: string
  styleEventSummaryBgColor: string
  styleEventSummaryBgColorDark: string
  commentsMode: CommentsMode
}

export const liveblogThemeConfigurationDefaultValues: LiveblogThemeConfiguration = {
  refresh: 'notify',
  sort: 'desc',
  sharing: "off",
  limit : 15,
  deepLinkLimit: 50,
  deepLinkDepth: 0,
  webEmbedDefaultConstraint: "consent",
  webEmbedConstraints: {},
  automaticSummary: "chapters",
  automaticSummaryHighlightsLimit: 8,
  eventTagNavigation: "filter-list",
  useSlideshow: true,
  layout: "default",
  layoutAuthorPosition: "top",
  layoutEventTagPosition: "top",
  styleUseCards: false,
  styleInvertTime: false,
  styleInvertHighlightEvents: false,
  styleInvertStickyEvents: false,
  styleInvertSummaryEvents: false,
  stylePrimaryColor: "",
  stylePrimaryColorDark: "",
  styleSecondaryColor: "",
  styleSecondaryColorDark: "",
  styleTextColor: "",
  styleTextColorDark: "",
  styleTextMutedColor: "",
  styleTextMutedColorDark: "",
  styleBgColor: "",
  styleBgColorDark: "",
  styleEventHighlightBgColor: "",
  styleEventHighlightBgColorDark: "",
  styleEventStickyBgColor: "",
  styleEventStickyBgColorDark: "",
  styleEventSummaryBgColor: "",
  styleEventSummaryBgColorDark: "",
  commentsMode: "default",
}

// Liveblog Core Widget

export interface LiveblogCoreWidgetInitialData {
  gameShowResponse?: IGameShowResponse
  uniqueId?: string
  isSSR?: boolean
}

// Liveblog Widget

export interface LiveblogWidgetInitialData extends LiveblogCoreWidgetInitialData {
  configuration?: LiveblogThemeConfiguration
  css?: string
  liveblogUrl?: string
}


export const liveblogWidgetConfigurationLimitMin = 1;
export const liveblogWidgetConfigurationLimitMax = 1000;

export const liveblogThemeConfigurationPropsParser: PropsParser<LiveblogThemeConfiguration> = {
  refresh: i => parseStringEnum<RefreshType>("refresh", i, refreshValues, liveblogThemeConfigurationDefaultValues.refresh),
  sort: i => parseStringEnum<SortOrder>("sort", i, sortValues, liveblogThemeConfigurationDefaultValues.sort),
  webEmbedDefaultConstraint: i => parseStringEnum<ProviderConstraintValue>("webEmbedDefaultConstraint", i, providerConstraintValues, liveblogThemeConfigurationDefaultValues.webEmbedDefaultConstraint),
  webEmbedConstraints: i => parseJSON("webEmbedConstraints", i, liveblogThemeConfigurationDefaultValues.webEmbedConstraints),
  automaticSummary: i => parseStringEnum<AutomaticSummaryType>("automaticSummary", i, automaticSummaryTypes, liveblogThemeConfigurationDefaultValues.automaticSummary),
  automaticSummaryHighlightsLimit: i => parseNumber(i, 1, 25, liveblogThemeConfigurationDefaultValues.automaticSummaryHighlightsLimit),
  eventTagNavigation: i => parseStringEnum<EventTagNavigationType>("eventTagNavigation", i, eventTagNavigationTypes, liveblogThemeConfigurationDefaultValues.eventTagNavigation),
  useSlideshow: i => parseBoolean("useSlideshow", i, liveblogThemeConfigurationDefaultValues.useSlideshow),
  sharing: i => parseStringEnum<EventSharingType>("sharing", i, eventSharingTypes, liveblogThemeConfigurationDefaultValues.sharing),
  limit: i => parseNumber(i, liveblogWidgetConfigurationLimitMin, liveblogWidgetConfigurationLimitMax, liveblogThemeConfigurationDefaultValues.limit),
  deepLinkLimit: i => parseNumber(i, 0, liveblogWidgetConfigurationLimitMax, liveblogThemeConfigurationDefaultValues.deepLinkLimit),
  deepLinkDepth: i => parseNumber(i, 0, liveblogWidgetConfigurationLimitMax, 0),
  layout: i => parseStringEnum<LayoutType>("layout", i, layoutValues, liveblogThemeConfigurationDefaultValues.layout),
  layoutAuthorPosition: i => parseStringEnum<LayoutAuthorPosition>("layoutAuthorPosition", i, layoutAuthorPositionValues, liveblogThemeConfigurationDefaultValues.layoutAuthorPosition),
  layoutEventTagPosition: i => parseStringEnum<LayoutEventTagPosition>("layoutEventTagPosition", i, layoutEventTagPositionValues, liveblogThemeConfigurationDefaultValues.layoutEventTagPosition),
  styleUseCards: i => parseBoolean("styleUseCards", i, liveblogThemeConfigurationDefaultValues.styleUseCards),
  styleInvertTime: i => parseBoolean("styleInvertTime", i, liveblogThemeConfigurationDefaultValues.styleInvertTime),
  styleInvertHighlightEvents: i => parseBoolean("styleInvertHighlightEvents", i, liveblogThemeConfigurationDefaultValues.styleInvertHighlightEvents),
  styleInvertStickyEvents: i => parseBoolean("styleInvertStickyEvents", i, liveblogThemeConfigurationDefaultValues.styleInvertStickyEvents),
  styleInvertSummaryEvents: i => parseBoolean("styleInvertSummaryEvents", i, liveblogThemeConfigurationDefaultValues.styleInvertSummaryEvents),
  stylePrimaryColor: i => parseStringFormat("stylePrimaryColor", i, colorRegex, liveblogThemeConfigurationDefaultValues.stylePrimaryColor),
  stylePrimaryColorDark: i => parseStringFormat("stylePrimaryColorDark", i, colorRegex, liveblogThemeConfigurationDefaultValues.stylePrimaryColorDark),
  styleSecondaryColor: i => parseStringFormat("styleSecondaryColor", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleSecondaryColor),
  styleSecondaryColorDark: i => parseStringFormat("styleSecondaryColorDark", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleSecondaryColorDark),
  styleTextColor: i => parseStringFormat("styleTextColor", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleTextColor),
  styleTextColorDark: i => parseStringFormat("styleTextColorDark", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleTextColorDark),
  styleTextMutedColor: i => parseStringFormat("styleTextMutedColor", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleTextMutedColor),
  styleTextMutedColorDark: i => parseStringFormat("styleTextMutedColorDark", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleTextMutedColorDark),
  styleBgColor: i => parseStringFormat("styleBgColor", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleBgColor),
  styleBgColorDark: i => parseStringFormat("styleBgColorDark", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleBgColorDark),
  styleEventHighlightBgColor: i => parseStringFormat("styleEventHighlightBgColor", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleEventHighlightBgColor),
  styleEventHighlightBgColorDark: i => parseStringFormat("styleEventHighlightBgColorDark", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleEventHighlightBgColorDark),
  styleEventStickyBgColor: i => parseStringFormat("styleEventStickyBgColor", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleEventStickyBgColor),
  styleEventStickyBgColorDark: i => parseStringFormat("styleEventStickyBgColorDark", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleEventStickyBgColorDark),
  styleEventSummaryBgColor: i => parseStringFormat("styleEventSummaryBgColor", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleEventSummaryBgColor),
  styleEventSummaryBgColorDark: i => parseStringFormat("styleEventSummaryBgColorDark", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleEventSummaryBgColorDark),
  commentsMode: i => parseStringEnum<CommentsMode>("commentsMode", i, commentsModeValues, liveblogThemeConfigurationDefaultValues.commentsMode),
}

