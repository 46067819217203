import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {ApiImage} from "../ApiImage";
import {aspectFit, exactSizeImageUrl} from "../converter/imageUrl";
import {OEmbed} from "./OEmbed";
import classNames from "classnames";
import {ExternalLink} from "react-feather";
import useDebouncedResizeObserver from "@webng/react-app-common/src/utils/useDebouncedResizeObserver";
import {useTrackingFunction} from "../LiveblogRenderContext";

interface SimpleWebEmbedViewProps {
  oembed: OEmbed
}

export function SimpleWebEmbedView({oembed}: SimpleWebEmbedViewProps) {
  const {t} = useTranslation('liveblog');
  const {ref,  width: thumbContainerwidth} = useDebouncedResizeObserver(250);
  const trackingFunction = useTrackingFunction();
  const trackWebEmbed = useCallback((url: string|undefined) => {
    trackingFunction({t: "s_wem", url: url?.slice(0,256), ts: Date.now()})
  }, [trackingFunction])



  const thumb = useMemo(() => {
    if (oembed.thumbnail_url) {
      if (oembed.thumbnail_width && oembed.thumbnail_height && oembed.thumbnail_width > 0 && oembed.thumbnail_height > 0) {
        let srcSet:string[] = [];
        let src:string = '';
        const ratio = oembed.thumbnail_height / oembed.thumbnail_width;

        const sizes = [[360, 360*ratio], [540, 540*ratio], [720, 720*ratio], [1080, 1080*ratio], [1200, 1200*ratio], [1440, 1440*ratio], [oembed.thumbnail_width, oembed.thumbnail_width*ratio]].sort((w1, w2) => w1[0] - w2[0]);

        let currentSize = 0;
        let width = 0;
        // do not create images larger than the thumbnail original size
        while (sizes[currentSize] && sizes[currentSize][0] <= oembed.thumbnail_width ) {
          width = sizes[currentSize][0];
          const height = sizes[currentSize][1];
          const thumbSize = aspectFit({}, {width: width, height: height});
          const thumbUrl = exactSizeImageUrl(oembed.thumbnail_url, {...thumbSize, format: 'webp'}).url;
          srcSet.push(`${thumbUrl} ${width}w`);
          src = thumbUrl;
          currentSize++;
        }

        return {
          src: src,
          srcSet: srcSet,
          // maxWidth: width,
          maxWidth: Math.min(width, 720),
          width: oembed.thumbnail_width,
          height: oembed.thumbnail_width*ratio
        }
      } else {
        const thumbUrl = exactSizeImageUrl(oembed.thumbnail_url, {width: 720, height: 378, format: 'webp'}).url;

        return {
          src: thumbUrl,
          maxWidth: 720
          // maxWidth: 640
        }
      }
    }
  }, [oembed.thumbnail_url, oembed.thumbnail_height, oembed.thumbnail_width])

  const icon = useMemo(() => {
    if(oembed.icon) {
      return {
        src: exactSizeImageUrl(oembed.icon, {width: 32, height: 32}).url
      }
    }
  }, [oembed.icon])

  const onClick = useCallback(() => {
    trackWebEmbed(oembed.url);
  }, [trackWebEmbed, oembed.url])

  return <a href={oembed.url} onClick={onClick} target="_blank" rel="noopener" className={classNames("tik4-we-simple", { "tik4-we-simple--thumb": thumb, "tik4-we-simple--icon": !thumb})} {...(thumb ? {style: {maxWidth: Math.max(thumb.maxWidth, 360)}}:{})}>
    <div className="tik4-we-simple__container">
      {thumb &&
        <div className="tik4-we-simple__thumb" ref={ref}>
          { thumbContainerwidth && <ApiImage
            className="tik4-we-simple__thumb__img"
            src={thumb.src}
            sizes={`(max-width: 100vw) ${Math.min(thumbContainerwidth, thumb.maxWidth)}px, 100vw`}
            srcSet={thumb.srcSet && thumb.srcSet.join(", ")}
            width={thumb.width}
            height={thumb.height}
            alt={oembed.title} />}
        </div>
      }
      { !thumb &&
        <div className="tik4-we-simple__icon">
          {/* TODO:Timo könnte man noch optimieren: also srcset mit verschieden generierten images wie beim thumb */}
          { icon ?
            <ApiImage
              src={icon.src}
              alt={oembed.provider_name}
              className="tik4-we-simple__icon__img"
              />
          :
          <ExternalLink className="tik4-we-simple__icon__fallback tik4-icon" />
        }
        </div>
      }

      <div className="tik4-we-simple__content">
        { oembed.kicker && <div className="tik4-we-simple__kicker">
          { oembed.access && <div className="tik4-we-simple__access" data-access={oembed.access}></div> }
          {oembed.kicker}
        </div> }
        { (oembed.access && !oembed.kicker) && <div className="tik4-we-simple__access" data-access={oembed.access}></div> }
        { oembed.title && <div className="tik4-we-simple__title">{oembed.title}</div> }
        { oembed.description && <div className="tik4-we-simple__description">{oembed.description}</div> }
        { (oembed.author_name || oembed.domain) && <div className="tik4-we-simple__meta">{[oembed.author_name, oembed.domain].filter(n => n).join(t('liveblog.webembed.meta.separator'))}</div> }
      </div>
    </div>
  </a>
}
