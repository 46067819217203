import {ColorScheme, colorSchemeValues} from "@webng/liveblog";
import {parseLocale, parseStringEnum, parseStringFormat, PropsParser,} from "@webng/validations";
import {supportedLocales, SupportedLocaleType} from "@webng-types/i18n";
import {liveblogThemeConfigurationDefaultValues} from "./liveblog";

export const colorRegex = /^$|^#[0-9A-F]{3}([0-9A-F]{3})?$/

export const useCookiesValues = ["none", "functional", "all"] as const
export type UseCookiesType = typeof useCookiesValues[number]

export interface SharedThemeConfiguration {
  locale: SupportedLocaleType
  colorScheme: ColorScheme
  useCookies: UseCookiesType
}

export const sharedThemeConfigurationDefaultValues: SharedThemeConfiguration = {
  locale: "",
  colorScheme: "light",
  useCookies: "all"
}

export const sharedThemeConfigurationPropsParser: PropsParser<SharedThemeConfiguration> = {
  locale: i => parseLocale(i, supportedLocales) as SupportedLocaleType || "", //TODO check
  colorScheme: i => parseStringEnum<ColorScheme>("colorScheme", i, colorSchemeValues, sharedThemeConfigurationDefaultValues.colorScheme),
  useCookies: i => parseStringEnum<UseCookiesType>("useCookies", i, useCookiesValues, sharedThemeConfigurationDefaultValues.useCookies)
}
