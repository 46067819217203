import {IEvent} from "@webng-types/write-model";

export const SUMMARY_TAG_ID = "st_summary";

export function isSummaryEvent(event: IEvent) {
  return event.tag_ids?.includes(SUMMARY_TAG_ID) || false
}

export function isSystemTag(tagId: string) {
  return tagId.startsWith("st_")
}
