import React, {useRef} from "react";

import {IEvent, IGame} from "@webng-types/write-model";
import {getHighlightPostText} from "../utils/eventUtils";
import {useNiceDateTime} from "@webng/react-app-common/src";
import {OnSummaryItemClickHandler} from "../Liveblog";


// import YellowCard from "@webng/sports/icons/soccer/yellow-card";


function TeaserWidgetSliderItemDateTime({event, className} : {event: IEvent, className?: string}) {
  const {date, time} = useNiceDateTime(event.created_at);
  return <>
    <div className={`tik4-tws__dt ${className || ""}`}>
      {date &&
        <>
          <span className="tik4-tws__dt__d">{date}</span>
          <span className="tik4-tws__dt__div"><span className="tik4-tws__dt__div__c">,</span></span>
        </>
      }
      <span className="tik4-tws__dt__t">{time}</span>
    </div>
  </>
}

interface TeaserWidgetSliderItemProps {
  game: IGame
  event: IEvent
  onClick?: OnSummaryItemClickHandler
  liveblogUrl: string
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void
}

export const TeaserWidgetSliderItem = React.forwardRef<HTMLAnchorElement, TeaserWidgetSliderItemProps>(function TeaserWidgetSliderItemImp({event, game, liveblogUrl, onClick, onKeyDown}, ref) {
  const keyDownPosition = useRef<string|undefined>();
  // const editor = useMemo(() => {
  //   return event.editor || ((event.user_id && game.editors) ? game.editors?.find((m) => m._id === event.user_id): undefined)
  // }, [event.editor, event.user_id, game.editors])

  function handlePointerDown(e: React.PointerEvent<HTMLElement>) {
    keyDownPosition.current = `${e.clientX}:${e.clientY}`;
  }

  function handlePointerUp(e: React.PointerEvent<HTMLElement>) {
    if(onClick && keyDownPosition.current && keyDownPosition.current === `${e.clientX}:${e.clientY}`) {
      onClick(event);
    }
    keyDownPosition.current = undefined;
  }

  // const IconTemp = 1 >= 2 ? YellowCard : false;
  const IconTemp = false;

  return <a href={`${liveblogUrl}#tickaroo_event_id=${encodeURIComponent(event.local_id!)}`} className={`tik4-tws__i ${event.highlight ? "tik4-tws__i--highlight" : ""} ${IconTemp ? "tik4-tws__i--wi" : ""}`} ref={ref} role="button" onPointerDown={handlePointerDown} onPointerUp={handlePointerUp} onKeyDown={onKeyDown} tabIndex={0}>
    <div className="tik4-tws__tl">
      <div className="tik4-tws__tl__b"></div>
      <div className="tik4-tws__tl__c">
        {/* @ts-ignore */}
        {IconTemp && <div className="tik4-tws__icon tik4-tws__icon--tl"><IconTemp className="tik4-tws__icon__i tik4-icon" /></div>}
        {/* <div className="tik4-tws__icon tik4-tws__icon--tl"><YellowCard className="tik4-tws__icon__i tik4-icon" /></div> */}
        <div className="tik4-tws__tl__d"></div>
        {/* datetime */}
        <TeaserWidgetSliderItemDateTime className="tik4-tws__dt--tl" event={event} />
      </div>
    </div>
    <div className="tik4-tws__ic">

      {/* item header */}
      <div className="tik4-tws__i__h">
        <div className="tik4-tws__i__hc">
          {/* @ts-ignore */}
          {IconTemp && <div className="tik4-tws__icon tik4-tws__icon--hi"><IconTemp className="tik4-tws__icon__i tik4-icon" /></div>}
          {/* datetime */}
          <TeaserWidgetSliderItemDateTime event={event} />
        </div>
      </div>

      {/* content */}
      <div className="tik4-tws__c">
        { getHighlightPostText(event) }
      </div>
    </div>
  </a>
})
