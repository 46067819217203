import {LoadErrorView} from "../LoadErrorView";
import {LiveblogCoreWidget} from "../liveblog-core/LiveblogCoreWidget";
import {EnvironmentProvider, ErrorBoundary} from "@webng/react-app-common";
import globalSettings from "../globalSettings";
import {LiveblogWidgetProps} from "./LiveblogWidgetProps";
import React, {ForwardedRef, forwardRef, useMemo} from "react";
import {useLiveblogWidgetData} from "./useLiveblogWidgetData";
import {parseObject, parsePartialObject} from "@webng/validations";
import {
  LiveblogThemeConfiguration,
  liveblogThemeConfigurationDefaultValues,
  liveblogThemeConfigurationPropsParser,
  SharedThemeConfiguration,
  sharedThemeConfigurationDefaultValues,
  sharedThemeConfigurationPropsParser
} from "@webng-types/embedjs";
import {LiveblogCoreApi} from "../liveblog-core/LiveblogCoreWidgetProps";
import {I18nWrapper} from "../I18nWrapper";

interface LiveblogControllerProps extends LiveblogWidgetProps {
  overrideConfiguration: Partial<LiveblogThemeConfiguration & SharedThemeConfiguration>
}

function LiveblogControllerImp({ clientId, themeId, liveblogId, eventId, initialData, customerConsent, overrideConfiguration, disableTracking }: LiveblogControllerProps, ref: ForwardedRef<LiveblogCoreApi>) {
  const { error, configuration, css, ...coreInitialData } = useLiveblogWidgetData({
    clientId,
    themeId,
    liveblogId,
    limit: overrideConfiguration.limit,
    sort: overrideConfiguration.sort,
    initialData
  })

  const finalConfiguration = useMemo(() => {
    if (configuration) {
      const parsedConfiguration = parseObject({...liveblogThemeConfigurationPropsParser, ...sharedThemeConfigurationPropsParser}, configuration)
      const parsedOverrideConfiguration = parsePartialObject({...liveblogThemeConfigurationPropsParser, ...sharedThemeConfigurationPropsParser}, overrideConfiguration)
      return Object.assign({}, liveblogThemeConfigurationDefaultValues, sharedThemeConfigurationDefaultValues, parsedConfiguration, parsedOverrideConfiguration)
    } else {
      return undefined
    }
  }, [configuration, overrideConfiguration])

  if (error !== undefined) {
    return <LoadErrorView status={error} />
  } else if (finalConfiguration) {
    return <I18nWrapper locale={finalConfiguration.locale} gameShowResponse={initialData.gameShowResponse}>
      <LiveblogCoreWidget ref={ref}
        initialData={coreInitialData || {}}
        css={css || ""}
        eventId={eventId}
        liveblogId={liveblogId}
        customerConsent={customerConsent}
        disableTracking={disableTracking}
        {...finalConfiguration} />
    </I18nWrapper>
  } else {
    return <React.Fragment />
  }
}


const LiveblogController = forwardRef(LiveblogControllerImp)

function LiveblogWidgetImp({clientId, ...props}: LiveblogWidgetProps, ref: ForwardedRef<LiveblogCoreApi>) {
  return <React.StrictMode>
    <ErrorBoundary>
      <EnvironmentProvider apiHost={globalSettings.baseUrl} clientId={clientId}>
        <LiveblogController ref={ref} clientId={clientId} {...props} />
      </EnvironmentProvider>
    </ErrorBoundary>
  </React.StrictMode>
}


export const LiveblogWidget = forwardRef(LiveblogWidgetImp)

