// Liveblog

export const layoutValues = ["default", "timeline", "masonry"] as const
export type LayoutType = typeof layoutValues[number]
export const colorSchemeValues = ['auto', 'light', 'dark'] as const
export type ColorScheme = typeof colorSchemeValues[number]
export const layoutAuthorPositionValues = ["top", "bottomLeft", "bottomRight", "off"] as const
export type LayoutAuthorPosition = typeof layoutAuthorPositionValues[number]
export const layoutEventTagPositionValues = ["top", "bottom", "off"] as const
export type LayoutEventTagPosition = typeof layoutEventTagPositionValues[number]
export const automaticSummaryTypes = ["chapters", "highlights", "highlights-slider", "game-summary", "none"] as const
export type AutomaticSummaryType = typeof automaticSummaryTypes[number]
export const eventTagNavigationTypes = ["filter-list", "filter-search", "none"] as const
export type EventTagNavigationType = typeof eventTagNavigationTypes[number]

// Liveblog Teaser

export const teaserLayoutValues = ["slider", "list"] as const
export type TeaserLayoutType = typeof teaserLayoutValues[number]

export const teaserTimelineValues = ["off", "on", "time"] as const
export type TeaserTimelineType = typeof teaserTimelineValues[number]

export const teaserContentTypeValues = ["highlights", "newest"] as const
export type TeaserContentType = typeof teaserContentTypeValues[number]

export const teaserLayoutLiveblogLinkValues = ["bottom", "off"] as const
export type TeaserLayoutLiveblogLink = typeof teaserLayoutLiveblogLinkValues[number]

export const teaserLayoutLiveIndicatorStyleValues = ["badge", "indicator", "off"] as const
export type TeaserLayoutLiveIndicatorStyleType = typeof teaserLayoutLiveIndicatorStyleValues[number]
