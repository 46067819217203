import React, {ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {SimpleWebEmbedView} from "./SimpleWebEmbedView";
import {useTranslation} from "react-i18next";
import {useConsentProvider, useTrackingFunction, useUniqueId} from "../LiveblogRenderContext";
import {OEmbed} from "./OEmbed";

interface ConsentWrapperProps {
  eventId: string|undefined
  blockId: string
  oembed: OEmbed
  children: ReactNode
}

interface ConsentTogglerProps {
  blockId: string
  value: boolean
  onChange: (newValue:boolean) => void
}

function ConsentToggler({blockId, value, onChange}: ConsentTogglerProps) {
  const {t} = useTranslation("liveblog")
  const uid = useUniqueId()

  const switchId = useMemo(() => 'tik4-we-toggle-switch--' + uid + '-' + blockId, [uid, blockId])

  const onClick = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)
  }, [onChange])

  return <div className={'tik4-we-toggle'}>
      <input id={switchId} type="checkbox" className="tik4-we-toggle__checkbox" onChange={onClick} checked={value} />
      <label htmlFor={switchId} className="tik4-we-toggle__label">
        <span className="tik4-we-toggle__label__text">
          {value ? t('liveblog.webembed.consent.hide_external_content') : t('liveblog.webembed.consent.show_external_content')}
        </span>
        <span className="tik4-we-toggle__label__hint">
        {value ? '' : t('liveblog.webembed.consent.hint')}
        </span>
      </label>
  </div>

}

export function ConsentWrapper({eventId, blockId, oembed, children}: ConsentWrapperProps) {
  const consentProvider = useConsentProvider();
  const trackingFunction = useTrackingFunction()

  const [consent, setConsent] = useState<boolean>(false)

  useEffect(() => {
    setConsent(consentProvider.getConsent(oembed.provider_name!))

    function consentListener(provider: string, consent: boolean) {
      if(oembed.provider_name === provider) {
        setConsent(consent)
      }
    }

    consentProvider.addConsentListener(consentListener)
    return function() {
      consentProvider.removeConsentListener(consentListener)
    }
  }, [consentProvider, oembed.provider_name, setConsent])

  const onToggle = useCallback((newValue: boolean) => {
    trackingFunction({t: "a_con", e: eventId, s: newValue ? 1 : 0, url: oembed.provider_name })
    consentProvider.requestConsent(oembed.provider_name!, newValue)
  }, [consentProvider, oembed.provider_name, trackingFunction, eventId])

  if(consent) {
    return <React.Fragment>
      {children}
      <ConsentToggler blockId={blockId} value={true} onChange={onToggle} />
    </React.Fragment>
  } else {
    return  <React.Fragment>
      <SimpleWebEmbedView oembed={oembed} />
      <ConsentToggler blockId={blockId} value={false} onChange={onToggle} />
    </React.Fragment>
  }

}
