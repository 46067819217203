import {IScoreboardScore, ISetBasedScoreboard} from "@webng-types/write-model";
import React, {ReactNode} from "react";
import {SetBasedScoreboardDisplayTypes} from "@webng/sports";
import classNames from "classnames";
import EventTeamIcon from "../event/EventTeamIcon";
import calculateSetScore from "../utils/calculateSetScore";
import {useTranslation} from "react-i18next";

interface SetBasedScoreboardProps {
  scoreboard: ISetBasedScoreboard;
  isMain?: boolean;
  scoreComponent?: (scores?: IScoreboardScore[]) => ReactNode;
}

export default function SetBasedScoreboard({scoreboard, scoreComponent, isMain = false}: SetBasedScoreboardProps) {
  const displayType = scoreboard.display_type || SetBasedScoreboardDisplayTypes.DEFAULT_ONLY_SCORES
  const thumbDisplayMode = [SetBasedScoreboardDisplayTypes.THUMB, SetBasedScoreboardDisplayTypes.THUMB_ONLY_SCORES].find(val => val === displayType)

  const imageSize = isMain ? (thumbDisplayMode ? 34 : 34) : (thumbDisplayMode ? 32 : 20)

  const ht_short_name = scoreboard.home_team?.short_name || scoreboard.home_team?.name
  const ht_name = scoreboard.home_team?.name || scoreboard.home_team?.short_name

  const at_short_name = scoreboard.away_team?.short_name || scoreboard.away_team?.name
  const at_name = scoreboard.away_team?.name || scoreboard.away_team?.short_name

  return <div>
    <div className={classNames("tik4-ssb", {
        'tik4-ssb--m': isMain,
        'tik4-ssb--t': thumbDisplayMode
      })}>
      <div className="tik4-ssb__sb">

        <div className="tik4-ssb__ts">
          <div className="tik4-ssb__tl tik4-ssb__lr"></div>

          <div className="tik4-ssb__t tik4-ssb__sr">
            <div className="tik4-ssb__t__i">
              <EventTeamIcon
                  className="tik4-ssb__t__img"
                  placeholderClassName="tik4-ssb__t__ifb"
                  image={scoreboard.home_team?.image}
                  size={imageSize}
                  name={scoreboard.home_team?.short_name || scoreboard.home_team?.name} />
            </div>
            { !thumbDisplayMode &&
              <div className="tik4-ssb__t__n">
                <div className="tik4-ssb__t__n__sn">{ht_short_name}</div>
                <div className="tik4-ssb__t__n__n">{ht_name}</div>
              </div>
            }
          </div>

          <div className="tik4-ssb__t tik4-ssb__sr">
            <div className="tik4-ssb__t__i">
              <EventTeamIcon
                    className="tik4-ssb__t__img"
                    placeholderClassName="tik4-ssb__t__ifb"
                    image={scoreboard.away_team?.image}
                    size={imageSize}
                    name={scoreboard.away_team?.short_name || scoreboard.away_team?.name} />
            </div>
            { !thumbDisplayMode &&
              <div className="tik4-ssb__t__n">
                <div className="tik4-ssb__t__n__sn">{at_short_name}</div>
                <div className="tik4-ssb__t__n__n">{at_name}</div>
              </div>
            }
          </div>
        </div>

        <div className="tik4-ssb__sw">
          <div className="tik4-ssb__swi">
            <div className="tik4-ssb__s">
              {scoreComponent
                ? scoreComponent(scoreboard.scores)
                : <Scores scores={scoreboard.scores}/>
              }
            </div>
          </div>
        </div>

        {[SetBasedScoreboardDisplayTypes.DEFAULT, SetBasedScoreboardDisplayTypes.THUMB].find(val => val === displayType) &&
          <div className="tik4-ssb__ss">
            <SummedScore scores={scoreboard.scores || []} />
          </div>
        }

      </div>
    </div>

  </div>
}

export function SetBasedEmptyScore() {
  return <span className="tik4-ssb__es">–</span>
}

function Scores({scores}: {scores?: IScoreboardScore[]}){
  return <>
    {(scores || []).map((score, index) =>
      <div className="tik4-ssb__si" key={`scores-${index}`}>
        <div className="tik4-ssb__sl tik4-ssb__lr">{index+1}</div>
        <div className="tik4-ssb__sv tik4-ssb__sr">
          {score.home_score || <SetBasedEmptyScore />}
        </div>
        <div className="tik4-ssb__sv tik4-ssb__sr">
          {score.away_score || <SetBasedEmptyScore />}
        </div>
      </div>
    )}
  </>
}

function SummedScore({scores}: { scores: IScoreboardScore[] }) {
  const {t} = useTranslation("liveblog");
  const results = calculateSetScore(scores);
  return <>
    <div className="tik4-ssb__sl tik4-ssb__sl--total tik4-ssb__lr">{t('liveblog.sport.set_based_scoreboard.summed_score.label')}</div>
    <div className="tik4-ssb__sv tik4-ssb__sr">{results[0] != null ? results[0] : <SetBasedEmptyScore />}</div>
    <div className="tik4-ssb__sv tik4-ssb__sr">{results[1] != null ? results[1] : <SetBasedEmptyScore />}</div>
  </>;
}
