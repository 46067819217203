import {getHighlightPostText} from "../utils/eventUtils";
import {IEvent, IGame, IGameShowResponse, ILocalRef} from "@webng-types/write-model";
import {TeaserContentType} from "../types";
import {useMemo} from "react";

function eventsForRefs(refList: {refs?: ILocalRef[]|IEvent[]}|undefined, events: IEvent[], reverse: boolean) {
  if(refList && refList.refs) {
    const ret = refList.refs.map(ref => events.find(event => event.local_id === ref.local_id)).filter(e => !!e) as IEvent[]
    if(reverse) ret.reverse()
    return ret
  } else {
    return []
  }
}

export function useTeaserWidgetEvents({gameShowResponse, content, limit}: {gameShowResponse: IGameShowResponse, content: TeaserContentType, limit: number}): {events: IEvent[], game: IGame} {
  const game = gameShowResponse.game!;
  const events = gameShowResponse.events;

  let eventsToShow = useMemo(() => {
    if(content === 'highlights') {
      return eventsForRefs(game.summary?.highlights, events || [], false).filter(h => getHighlightPostText(h) !== "").slice(0, limit)
    } else {
      return eventsForRefs({refs: game.events}, events || [], false).filter(h => getHighlightPostText(h) !== "").slice(0, limit)
    }
  }, [events, game, content, limit])

  return {events: eventsToShow, game}
}
