import React, {ForwardedRef, forwardRef, useCallback, useEffect, useMemo, useRef} from "react";
import {LiveblogTeaserCoreWidgetProps, LiveblogTeaserWidgetApi} from "./LiveblogTeaserWidgetProps";
import {
  DefaultConsentProvider,
  LiveblogEvent,
  LiveblogRenderContext,
  LiveblogRenderContextProvider,
  TeaserWrapper,
  TrackingFunction
} from "@webng/liveblog";
import {TeaserWidget} from "@webng/liveblog/src/teaser-widget/TeaserWidget";
import * as externalUserProvider from "../user";
import {IEvent} from "@webng-types/write-model";
import {useLiveblogTeaserAnalyticsAgent} from "./useLiveblogTeaserAnalyticsAgent";
import {fireTickarooLiveblogTeaserInitEvent, fireTickarooLiveblogTeaserTracking} from "../events";
import {useTeaserWidgetEvents} from "@webng/liveblog/src/teaser-widget/useTeaserWidgetEvents";

type LiveblogTeaserCoreControllerProps = Omit<LiveblogTeaserCoreWidgetProps, "locale">

function LiveblogTeaserWidgetCoreImp({ liveblogId, colorScheme, layout, styleTextColor, styleTextColorDark, styleTextMutedColor, styleTextMutedColorDark, styleBgColor, styleBgColorDark, styleLinkColor, styleSurfaceColorDark, styleSurfaceColor, styleLinkColorDark, css, limit, styleInvertTime, useCookies, initialData, disableTracking, content, liveblogUrl, layoutLiveIndicatorStyle, layoutLiveblogLink, title, layoutTimeline, showTitle }: LiveblogTeaserCoreControllerProps, ref: ForwardedRef<LiveblogTeaserWidgetApi>) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const agent = useLiveblogTeaserAnalyticsAgent(liveblogId, useCookies === 'all', disableTracking);

  const trackingFunction: TrackingFunction = useCallback((e: LiveblogEvent) => {
    if(agent) {
      agent.track(e)
      if (wrapperRef.current && wrapperRef.current.parentElement) {
        fireTickarooLiveblogTeaserTracking(wrapperRef.current.parentElement, e)
      }
    }
  }, [agent])

  // dispatch init event
  useEffect(() => {
    if (wrapperRef.current && wrapperRef.current.parentElement) {
      fireTickarooLiveblogTeaserInitEvent(wrapperRef.current.parentElement, { liveblogId: liveblogId })
    }
  }, [liveblogId])

  useEffect(() => {
    trackingFunction({ t: 't_ini' })
  }, [trackingFunction])

  const renderContext: Partial<LiveblogRenderContext> = useMemo(() => {
    const useFunctionalCookies = useCookies === 'all' || useCookies === 'functional'
    return {
      consentProvider: new DefaultConsentProvider(useFunctionalCookies),
      webEmbedDefaultProviderConstraint: 'disabled',
      webEmbedProviderConstraints: {},
      showEventSharing: 'off',
      layoutAuthorPosition: 'off',
      commentsMode: 'disabled',
      externalUserProvider: externalUserProvider,
      uniqueId: initialData.uniqueId,
      useFunctionalCookies,
      trackingFunction: trackingFunction
    }
  }, [useCookies, initialData.uniqueId, trackingFunction]);


  const onClick = useCallback((event: IEvent) => {
    trackingFunction({t: 'a_tli', m: event.local_id})
  }, [trackingFunction])

  const onGotoLiveblogClick = useCallback((e: React.MouseEvent) => {
    trackingFunction({t: 'a_tla'})
  }, [trackingFunction])

  const {game, events} = useTeaserWidgetEvents({gameShowResponse: initialData.gameShowResponse, content, limit})

  return <LiveblogRenderContextProvider context={renderContext}>
    <TeaserWrapper
      ref={wrapperRef}
      liveblogId={liveblogId}
      invertTime={styleInvertTime}
      layout={layout}
      layoutLiveIndicatorStyle={layoutLiveIndicatorStyle}
      colorScheme={colorScheme}
      layoutTimeline={layoutTimeline}
      styleTextColor={styleTextColor}
      styleTextColorDark={styleTextColorDark}
      styleTextMutedColor={styleTextMutedColor}
      styleTextMutedColorDark={styleTextMutedColorDark}
      styleBgColor={styleBgColor}
      styleBgColorDark={styleBgColorDark}
      styleLinkColor={styleLinkColor}
      styleLinkColorDark={styleLinkColorDark}
      styleSurfaceColor={styleSurfaceColor}
      styleSurfaceColorDark={styleSurfaceColorDark}
      css={css}
      numberOfEvents={events.length}>

      <TeaserWidget
        title={title}
        liveblogUrl={liveblogUrl}
        onGotoLiveblogClick={onGotoLiveblogClick}
        layoutLiveIndicatorStyle={layoutLiveIndicatorStyle}
        layoutLiveblogLink={layoutLiveblogLink}
        layout={layout}
        showTitle={showTitle}
        limit={limit}
        game={game}
        events={events}
        onItemClick={onClick}
      />

    </TeaserWrapper>
  </LiveblogRenderContextProvider>
}

export const LiveblogTeaserWidgetCore = forwardRef<LiveblogTeaserWidgetApi, LiveblogTeaserCoreControllerProps>(LiveblogTeaserWidgetCoreImp)
