import React, {
  ForwardedRef,
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react"
import {useEnvironment} from "@webng/react-app-common";
import {fireTickarooLiveblogInitEvent, fireTickarooLiveblogTracking} from "../events";
import {
  DefaultConsentProvider,
  Liveblog,
  LiveblogEvent,
  LiveblogRenderContext,
  LiveblogRenderContextProvider,
  LiveblogWrapper,
  TrackingFunction
} from "@webng/liveblog";
import {LiveblogCoreApi, LiveblogCoreWidgetProps, NavigateToEventIdOptions,} from "./LiveblogCoreWidgetProps";
import * as externalUserProvider from '../user'
import {useLiveblogAnalyticsAgent} from "./analytics";
import {useLiveblogData} from "./useLiveblogData";
import {IEvent, IMedia, ITag} from "@webng-types/write-model";
import {LoadErrorView} from "../LoadErrorView";
import DpaTracking from "./DpaTracking";
import {CustomConsentProvider} from "./CustomConsentProvider";
import {useAdExtension} from "../../ads/useAdExtension";
import {AdManager} from "../../ads/AdManager";
import {scrollToElement} from "../../utils/scrollToElement";
import {useHeaderOffset} from "../useHeaderOffset";


type LiveblogCoreControllerProps = Omit<LiveblogCoreWidgetProps, "locale" | "clientId">

const DPA_PRODUCTION_ORGA_ID = '58514026e4b08f124d8e26e6';
const DPA_STAGING_ORGA_ID = '583303a3e4b0085862630479';

function apiCanNotLoadMore(): Promise<void> {
  return Promise.reject("No more data");
}

function LiveblogCoreWidgetImp({ liveblogId, customerConsent, refresh, sort, colorScheme, layout, layoutAuthorPosition, layoutEventTagPosition, styleUseCards, stylePrimaryColor, styleSecondaryColor, stylePrimaryColorDark, styleSecondaryColorDark, styleTextColor, styleTextColorDark, styleTextMutedColor, styleTextMutedColorDark, styleBgColor, styleBgColorDark, styleEventHighlightBgColor, styleEventHighlightBgColorDark, styleEventStickyBgColor, styleEventStickyBgColorDark, styleEventSummaryBgColor, styleEventSummaryBgColorDark, css, limit, deepLinkLimit, deepLinkDepth, eventId, webEmbedDefaultConstraint, webEmbedConstraints, sharing, styleInvertHighlightEvents, styleInvertStickyEvents, styleInvertSummaryEvents, styleInvertTime, useSlideshow, automaticSummary, automaticSummaryHighlightsLimit, commentsMode, useCookies, initialData, disableTracking, eventTagNavigation }: LiveblogCoreControllerProps, ref: ForwardedRef<LiveblogCoreApi>) {
  const agent = useLiveblogAnalyticsAgent(liveblogId, useCookies === 'all', disableTracking);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const env = useEnvironment();
  const [adManager, setAdManager] = useState<AdManager>()

  const trackingFunction: TrackingFunction = useCallback((e: LiveblogEvent) => {
    if(agent) {
      agent.track(e)
      if (wrapperRef.current && wrapperRef.current.parentElement) {
        fireTickarooLiveblogTracking(wrapperRef.current.parentElement, e)
      }
    }
  }, [agent])

  const renderContext: Partial<LiveblogRenderContext> = useMemo(() => {
    const useFunctionalCookies = useCookies === 'all' || useCookies === 'functional'
    return {
      consentProvider: customerConsent ? new CustomConsentProvider(useFunctionalCookies, wrapperRef as RefObject<HTMLDivElement>) : new DefaultConsentProvider(useFunctionalCookies),
      webEmbedDefaultProviderConstraint: webEmbedDefaultConstraint,
      webEmbedProviderConstraints: Object.assign({}, webEmbedConstraints, { Website: 'full' }),
      showEventSharing: sharing,
      layoutAuthorPosition: layoutAuthorPosition,
      commentsMode: commentsMode,
      externalUserProvider: externalUserProvider,
      uniqueId: initialData.uniqueId,
      useFunctionalCookies,
      trackingFunction
    }
  }, [webEmbedDefaultConstraint, webEmbedConstraints, commentsMode, useCookies, sharing, layoutAuthorPosition, initialData.uniqueId, customerConsent, trackingFunction]);

  const {
    error,
    game,
    rows,
    pendingUpdate,
    milestones,
    goals,
    highlights,
    loadMoreBottom,
    loadMoreTop,
    backToTop,
    navigateToEventId,
    selectedTags,
    setSelectedTags,
    applyPendingUpdate,
    isLoadingBackToLive,
    isLoadingMoreTop,
    isLoadingMoreBottom,
    isLoadingPendingUpdate
  } = useLiveblogData({
    id: liveblogId,
    limit,
    deepLinkLimit,
    deepLinkDepth,
    trackingFunction,
    refresh,
    reverse: sort === 'asc',
    initialData: initialData.gameShowResponse,
    refreshImmediately: initialData.isSSR
  })

  const navigateToEventIdWrap = useCallback((eventId: string | undefined, options?: NavigateToEventIdOptions) => {
    if (eventId) {
      const existingElement = wrapperRef.current?.querySelector(`[data-tickaroo-event-id="${eventId}"]`);
      if (existingElement) {
        scrollToElement(existingElement, options);
        return Promise.resolve(true);
      } else {
        return navigateToEventId(eventId).then(async _ => {
          const pollForElement = (): Promise<Element | null> => {
            return new Promise((resolve, reject) => {
              const interval = 50;
              const timeout = 3000;
              const startTime = Date.now();

              const checkElement = () => {
                const element = wrapperRef.current?.querySelector(`[data-tickaroo-event-id="${eventId}"]`);
                if (element) {
                  resolve(element);
                } else if (Date.now() - startTime > timeout) {
                  resolve(null);
                } else {
                  setTimeout(checkElement, interval);
                }
              };

              checkElement();
            });
          };

          const element = await pollForElement();
          if (element) {
            scrollToElement(element, options);
            return true;
          } else {
            return false;
          }
        });
      }
    } else {
      return navigateToEventId(eventId).then(_ => true);
    }
  }, [navigateToEventId]);

  // provide imperative methods on the liveblog element
  useImperativeHandle<LiveblogCoreApi, LiveblogCoreApi>(ref, () => ({
    loadMoreTop: loadMoreTop || apiCanNotLoadMore,
    loadMoreBottom: loadMoreBottom || apiCanNotLoadMore,
    navigateToEventId: navigateToEventIdWrap,
    hasEventIdLoaded: function (eventId: string) {
      return !!rows.find(r => r.local_id === eventId)
    },
    setConsent: (provider: string, consent: boolean): void => {
      renderContext.consentProvider?.setConsent(provider, consent)
    },
    setAdManager
  }))

  // dispatch init event
  useEffect(() => {
    if (wrapperRef.current && wrapperRef.current.parentElement) {
      fireTickarooLiveblogInitEvent(wrapperRef.current.parentElement, { liveblogId: liveblogId })
    }
  }, [liveblogId])

  // dispatch tracking init event
  useEffect(() => {
    trackingFunction({ t: 't_ini' })
  }, [trackingFunction])

  // react to eventId
  useEffect(() => {
    if (eventId) {
      navigateToEventIdWrap(eventId)
    }
    // navigateToEventIdWrap is not in the dependencies list as it would cause
    // this callback to be called to often. we only want to call it for the initial eventId jump
    // eslint-disable-next-line
  }, [eventId])

  const onMilestoneClick = useCallback((milestone: IEvent) => {
    trackingFunction({ t: 't_mil', e: milestone.local_id })

    navigateToEventIdWrap(milestone.local_id)
  }, [navigateToEventIdWrap, trackingFunction])

  const onHighlightClick = useCallback((highlight: IEvent) => {
    trackingFunction({ t: 't_hlt', e: highlight.local_id })

    navigateToEventIdWrap(highlight.local_id)
  }, [navigateToEventIdWrap, trackingFunction])

  const onGameSummaryClick = useCallback((summary: IEvent) => {
    trackingFunction({ t: 't_sgs', e: summary.local_id })

    navigateToEventIdWrap(summary.local_id)
  }, [navigateToEventIdWrap, trackingFunction])

  const onChangeTags = useCallback((tags: ITag[]|undefined) => {
    trackingFunction({ t: 't_tag' })
    if(tags === undefined || tags.length === 0) {
      setSelectedTags(undefined)
    } else {
      setSelectedTags(tags.map(t => t._id!))
    }
  }, [setSelectedTags, trackingFunction])

  const onMediaClick = useCallback((media: IMedia) => {
    trackingFunction({ t: 's_opn', m: media.local_id })
    import(/* webpackChunkName: "slideshow" */ '../../slideshow/slideshow').then(m => m.startSlideshow(rows, media.local_id, trackingFunction, env.apiHost))
  }, [rows, trackingFunction, env.apiHost])


  const onApplyPendingUpdates = useCallback(() => {
    applyPendingUpdate();
    setTimeout(() => wrapperRef.current && scrollToElement(wrapperRef.current), 10);
  }, [applyPendingUpdate, wrapperRef])

  const headerOffset = useHeaderOffset(!!pendingUpdate)

  const backToLiveLink = backToTop ? "#" : undefined
  const loadMoreTopLink = loadMoreTop ? "#" : undefined
  const loadMoreBottomLink = loadMoreBottom ? "#" : undefined

  const includeDpaTracking = !!game.upstreams?.find(u => u._type === "Tik::Model::Upstream::MarketplaceUpstream" && u.seller && (u.seller._id === DPA_PRODUCTION_ORGA_ID || u.seller._id === DPA_STAGING_ORGA_ID));

  const extension = useAdExtension(adManager, rows)


  if (error !== undefined) {
    return <LoadErrorView status={error} />
  } else {
    return <>
      <LiveblogRenderContextProvider context={renderContext}>
        <LiveblogWrapper
          ref={wrapperRef}
          liveblogId={liveblogId}
          sharing={sharing}
          invertHighlightEvents={styleInvertHighlightEvents}
          invertStickyEvents={styleInvertStickyEvents}
          invertSummaryEvents={styleInvertSummaryEvents}
          invertTime={styleInvertTime}
          colorScheme={colorScheme}
          layout={layout}
          layoutAuthorPosition={layoutAuthorPosition}
          layoutEventTagPosition={layoutEventTagPosition}
          styleUseCards={styleUseCards}
          stylePrimaryColor={stylePrimaryColor}
          stylePrimaryColorDark={stylePrimaryColorDark}
          styleSecondaryColor={styleSecondaryColor}
          styleSecondaryColorDark={styleSecondaryColorDark}
          styleTextColor={styleTextColor}
          styleTextColorDark={styleTextColorDark}
          styleTextMutedColor={styleTextMutedColor}
          styleTextMutedColorDark={styleTextMutedColorDark}
          styleBgColor={styleBgColor}
          styleBgColorDark={styleBgColorDark}
          styleEventHighlightBgColor={styleEventHighlightBgColor}
          styleEventHighlightBgColorDark={styleEventHighlightBgColorDark}
          styleEventStickyBgColor={styleEventStickyBgColor}
          styleEventStickyBgColorDark={styleEventStickyBgColorDark}
          styleEventSummaryBgColor={styleEventSummaryBgColor}
          styleEventSummaryBgColorDark={styleEventSummaryBgColorDark}
          headerOffset={headerOffset}
          css={css}>
          <Liveblog
            game={game}
            milestones={milestones}
            goals={goals}
            highlights={highlights}
            rows={rows}
            selectedTags={selectedTags}
            pendingUpdate={pendingUpdate}
            onChangeTags={onChangeTags}
            onMilestoneClick={onMilestoneClick}
            onHighlightClick={onHighlightClick}
            onGameSummaryClick={onGameSummaryClick}
            onMediaClick={useSlideshow ? onMediaClick : undefined}
            onBackToLiveClick={backToTop}
            onLoadMoreTopClick={loadMoreTop}
            onLoadMoreBottomClick={loadMoreBottom}
            onApplyPendingUpdates={onApplyPendingUpdates}
            backToLiveLink={backToLiveLink}
            loadMoreTopLink={loadMoreTopLink}
            loadMoreBottomLink={loadMoreBottomLink}
            isLoadingBackToLive={isLoadingBackToLive}
            isLoadingMoreTop={isLoadingMoreTop}
            isLoadingMoreBottom={isLoadingMoreBottom}
            isLoadingPendingUpdate={isLoadingPendingUpdate}
            automaticSummary={automaticSummary}
            automaticSummaryHighlightsLimit={automaticSummaryHighlightsLimit}
            eventTagNavigation={eventTagNavigation}
            extension={extension}
          />
        </LiveblogWrapper>
      </LiveblogRenderContextProvider>
      {includeDpaTracking && <DpaTracking />}
    </>
  }
}

export const LiveblogCoreWidget = forwardRef<LiveblogCoreApi, LiveblogCoreControllerProps>(LiveblogCoreWidgetImp)
