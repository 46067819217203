import {parseBoolean, parseNumber, parseStringEnum, parseStringFormat, PropsParser} from "@webng/validations";
import {
  TeaserContentType,
  teaserContentTypeValues,
  TeaserLayoutLiveIndicatorStyleType,
  teaserLayoutLiveIndicatorStyleValues,
  TeaserLayoutLiveblogLink, teaserLayoutLiveblogLinkValues,
  TeaserLayoutType,
  teaserLayoutValues,
  TeaserTimelineType, teaserTimelineValues, ColorScheme, colorSchemeValues
} from "@webng/liveblog";
import {liveblogThemeConfigurationDefaultValues} from "./liveblog";
import {colorRegex} from "./shared";



export interface LiveblogTeaserThemeConfiguration {
  layout: TeaserLayoutType
  layoutTimeline: TeaserTimelineType
  layoutLiveIndicatorStyle: TeaserLayoutLiveIndicatorStyleType
  layoutLiveblogLink: TeaserLayoutLiveblogLink

  showTitle: boolean
  styleInvertTime: boolean

  styleBgColor: string
  styleBgColorDark: string
  styleTextColor: string
  styleTextColorDark: string
  styleTextMutedColor: string
  styleTextMutedColorDark: string
  styleLinkColor: string
  styleLinkColorDark: string
  styleSurfaceColor: string
  styleSurfaceColorDark: string

  content: TeaserContentType
  limit: number
}

export const liveblogTeaserThemeConfigurationDefaultValues: LiveblogTeaserThemeConfiguration = {
  layout: "slider",
  layoutLiveIndicatorStyle: 'badge',
  layoutLiveblogLink: "bottom",
  layoutTimeline: "time",

  showTitle: true,
  styleInvertTime: false,
  styleBgColor: "",
  styleBgColorDark: "",
  styleTextColor: "",
  styleTextColorDark: "",
  styleTextMutedColor: "",
  styleTextMutedColorDark: "",
  styleLinkColor: "",
  styleLinkColorDark: "",
  styleSurfaceColor: "",
  styleSurfaceColorDark: "",

  content: "highlights",
  limit: 8,
}


export const liveblogTeaserThemeConfigurationPropsParser: PropsParser<LiveblogTeaserThemeConfiguration> = {
  layout: i => parseStringEnum<TeaserLayoutType>("layout", i, teaserLayoutValues, liveblogTeaserThemeConfigurationDefaultValues.layout),
  layoutLiveIndicatorStyle: i => parseStringEnum<TeaserLayoutLiveIndicatorStyleType>("layoutLiveIndicatorStyleValues", i, teaserLayoutLiveIndicatorStyleValues, liveblogTeaserThemeConfigurationDefaultValues.layoutLiveIndicatorStyle),
  layoutLiveblogLink: i => parseStringEnum<TeaserLayoutLiveblogLink>("layoutLiveblogLink", i, teaserLayoutLiveblogLinkValues, liveblogTeaserThemeConfigurationDefaultValues.layoutLiveblogLink),
  layoutTimeline: i => parseStringEnum<TeaserTimelineType>("layoutTimeline", i, teaserTimelineValues, liveblogTeaserThemeConfigurationDefaultValues.layoutTimeline),

  showTitle: i => parseBoolean("showTitle", i, liveblogTeaserThemeConfigurationDefaultValues.showTitle),
  styleInvertTime: i => parseBoolean("invertTime", i, liveblogTeaserThemeConfigurationDefaultValues.styleInvertTime),
  styleBgColor: i => parseStringFormat("styleBgColor", i, colorRegex, liveblogTeaserThemeConfigurationDefaultValues.styleBgColor),
  styleBgColorDark: i => parseStringFormat("styleBgColorDark", i, colorRegex, liveblogTeaserThemeConfigurationDefaultValues.styleBgColorDark),
  styleTextColor: i => parseStringFormat("styleTextColor", i, colorRegex, liveblogTeaserThemeConfigurationDefaultValues.styleTextColor),
  styleTextColorDark: i => parseStringFormat("styleTextColorDark", i, colorRegex, liveblogTeaserThemeConfigurationDefaultValues.styleTextColorDark),
  styleTextMutedColor: i => parseStringFormat("styleTextMutedColor", i, colorRegex, liveblogTeaserThemeConfigurationDefaultValues.styleTextMutedColor),
  styleTextMutedColorDark: i => parseStringFormat("styleTextMutedColorDark", i, colorRegex, liveblogTeaserThemeConfigurationDefaultValues.styleTextMutedColorDark),
  styleLinkColor: i => parseStringFormat("styleLinkColor", i, colorRegex, liveblogTeaserThemeConfigurationDefaultValues.styleLinkColor),
  styleLinkColorDark: i => parseStringFormat("styleLinkColorDark", i, colorRegex, liveblogTeaserThemeConfigurationDefaultValues.styleLinkColorDark),
  styleSurfaceColor: i => parseStringFormat("styleSurfaceColor", i, colorRegex, liveblogTeaserThemeConfigurationDefaultValues.styleSurfaceColor),
  styleSurfaceColorDark: i => parseStringFormat("styleSurfaceColorDark", i, colorRegex, liveblogTeaserThemeConfigurationDefaultValues.styleSurfaceColorDark),

  content: i => parseStringEnum<TeaserContentType>("content", i, teaserContentTypeValues, liveblogTeaserThemeConfigurationDefaultValues.content),
  limit: i => parseNumber(i, 1, 15, liveblogTeaserThemeConfigurationDefaultValues.limit),

}
