import { IGaelicScoreboard, ILocalRef } from "@webng-types/write-model";
import BlackCard from "@webng/sports/icons/gaelic_sports/black-card";
import RedCard from "@webng/sports/icons/soccer/red-card";
// import YellowCard from "@webng/sports/icons/soccer/yellow-card";
import React from "react";
import classNames from "classnames";
import EventTeamIcon from "../event/EventTeamIcon";
// import { ReactNode } from "react";

interface GaelicGameScoreboardProps {
    scoreboard: IGaelicScoreboard;
    isMain?: boolean;
}

function scrollTo(id: string) {
    const element = document.getElementById("event-" + id)
    if (element) {
      element.scrollIntoView(true)
    }
  }

export function GaelicGameScoreboard({scoreboard, isMain = false}: GaelicGameScoreboardProps) {
    const homeGoals = scoreboard.home_goals ?? 0
    const homePoints = scoreboard.home_points ?? 0
    const homeScore = 3 * homeGoals + homePoints
    const awayGoals = scoreboard.away_goals ?? 0
    const awayPoints = scoreboard.away_points ?? 0
    const awayScore = 3 * awayGoals + awayPoints

    const hasHomeGoalsOrPoints = scoreboard.home_goals != undefined || scoreboard.home_points != undefined
    const hasAwayGoalsOrPoints = scoreboard.away_goals != undefined || scoreboard.away_points != undefined

    const hasGoalsOrPoints = hasHomeGoalsOrPoints || hasAwayGoalsOrPoints

    const fallbackScoreOrPoint = hasGoalsOrPoints ? 0 : ""

    const homeGoalsDisplay = scoreboard.home_goals != undefined ? `${homeGoals}` : fallbackScoreOrPoint
    const awayGoalsDisplay = scoreboard.away_goals != undefined ? `${awayGoals}` : fallbackScoreOrPoint
    const homePointsDisplay = scoreboard.home_points != undefined ? `${homePoints}`: fallbackScoreOrPoint
    const awayPointsDisplay = scoreboard.away_points != undefined ? `${awayPoints}` : fallbackScoreOrPoint


    const homeBlackCards = scoreboard.home_black_cards
    const homeRedCards = scoreboard.home_red_cards
    const awayBlackCards = scoreboard.away_black_cards
    const awayRedCards = scoreboard.away_red_cards

    // const hasCards = homeBlackCards && homeBlackCards.length > 0
    const totalCards = [homeBlackCards?.length || 0, homeRedCards?.length || 0, awayBlackCards?.length || 0, awayRedCards?.length || 0].reduce((psum,a) => psum + a, 0);


    const ht_short_name = scoreboard.home_team?.short_name || scoreboard.home_team?.name
    const ht_name = scoreboard.home_team?.name || scoreboard.home_team?.short_name

    const at_short_name = scoreboard.away_team?.short_name || scoreboard.away_team?.name
    const at_name = scoreboard.away_team?.name || scoreboard.away_team?.short_name

    const imageSize = isMain ? 40 : 32;

    return <div className={classNames(
      "tik4-gsb",
      {
        "tik4-gsb--m": isMain,
        "tik4-gsb--e": !isMain,
        "tik4-gsb--c": totalCards > 0,
        "tik4-gsb--empty": !hasGoalsOrPoints
      }
      )}>

      { scoreboard.phase?.name &&
        <div className="tik4-gsb__ph">{scoreboard.phase?.name}</div>
      }

      { scoreboard.time_of_play &&
        <div className="tik4-gsb__top">{scoreboard.time_of_play}</div>
      }

      {/* spacer */}
      <div className="tik4-gsb__sp"></div>

      <div className="tik4-gsb__n tik4-gsb__n--ht" title={scoreboard.home_team?.name}>
        <div className="tik4-gsb__t__n__sn">{ht_short_name}</div>
        <div className="tik4-gsb__t__n__n">{ht_name}</div>
      </div>

      {/* team: hometeam */}
      <div className="tik4-gsb__i tik4-gsb__i--ht">
        <EventTeamIcon
            className="tik4-gsb__i__img"
            placeholderClassName="tik4-gsb__i__iph"
            image={scoreboard.home_team?.image}
            size={imageSize}
            name={scoreboard.home_team?.short_name || scoreboard.home_team?.name} />
      </div>

      {/* s: score */}
      <div className="tik4-gsb__s tik4-gsb__s--ht">
        <span className="tik4-gsb__s__g">{homeGoalsDisplay}</span><span className="tik4-gsb__s__d">-</span><span className="tik4-gsb__s__p">{homePointsDisplay}</span>
      </div>
      <div className="tik4-gsb__st tik4-gsb__st--ht">
        {homeScore >= 0 && `(${homeScore})`}
      </div>
      <div className="tik4-gsb__d">:</div>
      <div className="tik4-gsb__s tik4-gsb__s--at">
        <span className="tik4-gsb__s__g">{awayGoalsDisplay}</span><span className="tik4-gsb__s__d">-</span><span className="tik4-gsb__s__p">{awayPointsDisplay}</span>
      </div>
      <div className="tik4-gsb__st tik4-gsb__st--at">
        {awayScore >= 0 && `(${awayScore})`}
      </div>

      <div className="tik4-gsb__i tik4-gsb__i--at">
        <EventTeamIcon
            className="tik4-gsb__i__img"
            placeholderClassName="tik4-gsb__i__iph"
            image={scoreboard.away_team?.image}
            size={imageSize}
            name={scoreboard.away_team?.short_name || scoreboard.away_team?.name} />
      </div>

      <div className="tik4-gsb__n tik4-gsb__n--at" title={scoreboard.away_team?.name}>
        <div className="tik4-gsb__t__n__sn">{at_short_name}</div>
        <div className="tik4-gsb__t__n__n">{at_name}</div>
      </div>



      <div className="tik4-gsb__c tik4-gsb__c--ht">
        { scoreboard.home_black_cards?.map((card:ILocalRef, index:number) =>
          <BlackCard
            key={`gaelic-sports-scoreaboard-home-black-card-${index}`}
            className="tik4-gsb__c__i tik4-icon"
            onClick={() => {
              if(card.local_id) scrollTo(card.local_id!)
            }}/>
        )}
        { scoreboard.home_red_cards?.map((card, index) =>
          <RedCard
            key={`gaelic-sports-scoreaboard-home-red-card-${index}`}
            className="tik4-gsb__c__i tik4-icon"
            onClick={() => {
              if(card.local_id) scrollTo(card.local_id!)
            }}/>
        )}
      </div>

      <div className="tik4-gsb__c tik4-gsb__c--at">
        { scoreboard.away_black_cards?.map((card, index) =>
          <BlackCard
          key={`gaelic-sports-scoreaboard-away-black-card-${index}`}
          className="tik4-icon"
          onClick={() => {
            if(card.local_id) scrollTo(card.local_id!)
          }}/>
          )}
        { scoreboard.away_red_cards?.map((card, index) =>
          <RedCard
            key={`gaelic-sports-scoreaboard-away-red-card-${index}`}
            className="tik4-icon"
            onClick={() => {
              if(card.local_id) scrollTo(card.local_id!)
            }}/>
        )}

      </div>


    </div>
}
