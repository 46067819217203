import React from "react";
import {useTranslation} from "react-i18next";
import {SpanView} from "./HtmlView";
import {IQuoteContentBlock} from "@webng-types/write-model";

export function QuoteContentBlockView({block} : {block: IQuoteContentBlock}) {
  const {t,i18n} = useTranslation('liveblog')

  return <div className="tik4-quote">
    <blockquote className="tik4-quote__blockquote">
      <div className={`tik4-quote__text tik4-quote__text--${i18n.language} tik4-rich-text tik4-rich-text--${i18n.language}`}>
        <span className="tik4-quote__quotation-mark tik4-quote__quotation-mark--open">{t('liveblog.quote.quotation_mark.open')}</span><SpanView className="tik4-quote__text-content" html={block.text || ""} /><span className="tik4-quote__quotation-mark tik4-quote__quotation-mark--close">{t('liveblog.quote.quotation_mark.close')}</span>
      </div>
      {block.author &&
        <cite className={`tik4-quote__cite ${ block.source ? 'tik4-quote__cite--source' : ''}`}>
          { block.source ?
              <a className="tik4-quote__cite__source tik4-link tik4-link--muted" href={block.source} target="_blank" rel="noopener">{block.author}</a>
            :
              block.author
          }
        </cite>
      }
    </blockquote>
  </div>
}
