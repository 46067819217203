import React, {ReactNode} from "react";
import {IGame} from "@webng-types/write-model";

interface TeaserWidgetListProps {
  game: IGame
  children: ReactNode
}

export function TeaserWidgetList({game, children}: TeaserWidgetListProps) {
  return <React.Fragment>
    {game.summary?.highlights && game.summary.highlights.refs && game.summary.highlights.refs.length > 0 &&
    <div className="tik4-twl">
      <div className="tik4-twl__list" role="list">
        {children}
      </div>
    </div>
    }
  </React.Fragment>
}
