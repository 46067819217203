import {parsePartialTagProps, parseQueryProps, parseTagProps,} from "../utils/propsParser";
import {PropsParser} from "@webng/validations";
import {SharedThemeConfiguration} from "@webng-types/embedjs";
import globalSettings from "./globalSettings";
import ReactDOM from "react-dom/client";
import {notifyError} from "../analytics/notifyError";
import {ReactNode} from "react";
import {domContentLoaded} from "../utils/domContentLoaded";


export abstract class AbstractLiveblogElement<Configuration, TagProps, QueryProps, T> extends HTMLElement {
  private readonly wrapper: HTMLElement;
  private themeConfigurationPropsParser: PropsParser<Configuration & SharedThemeConfiguration>;
  private tagPropsParser: PropsParser<TagProps>;
  private queryPropsParser: PropsParser<QueryProps>;
  private props?: {tagProps: TagProps, queryProps: QueryProps, overrideConfigurationProps: Partial<Configuration & SharedThemeConfiguration>};
  private isRendered = false;
  private rerenderAfterHydrate = false
  protected liveblogComponent: T|undefined;
  private root: ReactDOM.Root|undefined

  protected constructor(themeConfigurationPropsParser: PropsParser<Configuration & SharedThemeConfiguration>, tagPropsParser: PropsParser<TagProps>, queryPropsParser: PropsParser<QueryProps>) {
    super();
    this.wrapper = this;
    this.themeConfigurationPropsParser = themeConfigurationPropsParser;
    this.tagPropsParser = tagPropsParser;
    this.queryPropsParser = queryPropsParser;
  }

  connectedCallback() {
    this.props = this.parseProps();
    if (this.props) {
      if (globalSettings.useHydrate && this.useHydrate(this.props)) {
        this.hydrateWidget(this.wrapper, this.props, (ref) => this.liveblogComponent = ref);
      } else {
        this.renderWidget(this.wrapper, this.props, (ref) => this.liveblogComponent = ref);
      }
    }
  }

  disconnectedCallback() {
    this.isRendered = false;
    this.liveblogComponent = undefined;
    this.root?.unmount()
    this.root = undefined
  }

  attributeChangedCallback(
    name: string,
    oldValue: string | null,
    newValue: string | null
  ) {
    if (this.isRendered && !this.rerenderAfterHydrate) {
      this.props = this.parseProps();
      this.renderWidget(this.wrapper, this.props, (ref) => this.liveblogComponent = ref);
    } else {
      this.rerenderAfterHydrate = true
    }
  }

  parseProps() {
    const tagProps = parseTagProps(this.tagPropsParser, this);
    const overrideConfigurationProps = parsePartialTagProps<Configuration & SharedThemeConfiguration>(
      this.themeConfigurationPropsParser,
      this
    );
    const queryProps = parseQueryProps(this.queryPropsParser);

    return {tagProps, queryProps, overrideConfigurationProps}
  }

  renderWidget(el: Element, props: {tagProps: TagProps, queryProps: QueryProps, overrideConfigurationProps: Partial<Configuration & SharedThemeConfiguration>}, refCallback: (ref: T) => void) {
    if(!this.root) {
      this.root = ReactDOM.createRoot(el, {
        onRecoverableError: (error: unknown, errorInfo: ReactDOM.ErrorInfo) => {
          notifyError(error, {
            digest: errorInfo.digest || "",
            componentStack: errorInfo.componentStack || ""
          })
        }
      })
    }
    this.root.render(this.renderImp(refCallback, props))
    this.isRendered = true;
    this.rerenderAfterHydrate = false;
  }

  hydrateWidget(el: Element, props: {tagProps: TagProps, queryProps: QueryProps, overrideConfigurationProps: Partial<Configuration & SharedThemeConfiguration>}, refCallback: (ref: T) => void) {
    return domContentLoaded(() => {
      const refCallbackWrapper = (ref: T) => {
        if(this.rerenderAfterHydrate) {
          setTimeout(() => {
            this.props = this.parseProps();
            this.renderWidget(this.wrapper, this.props, (ref) => this.liveblogComponent = ref);
          }, 100)
        } else {
          this.isRendered = true;
        }

        refCallback(ref)
      }

      this.root = ReactDOM.hydrateRoot(el, this.renderImp(refCallbackWrapper, props), {
        onRecoverableError: (error: unknown, errorInfo: ReactDOM.ErrorInfo) => {
          notifyError(error, {
            digest: errorInfo.digest || "",
            componentStack: errorInfo.componentStack || ""
          })
        }
      })
    })
  }

  abstract useHydrate(props: {tagProps: TagProps, queryProps: QueryProps, overrideConfigurationProps: Partial<Configuration & SharedThemeConfiguration>}): boolean

  abstract renderImp(ref: (r: T) => void, props: {tagProps: TagProps, queryProps: QueryProps, overrideConfigurationProps: Partial<Configuration & SharedThemeConfiguration>}): ReactNode
}
