import {IGameShowResponse} from "@webng-types/write-model";
import {
  LiveblogThemeConfiguration,
  liveblogThemeConfigurationDefaultValues,
  liveblogThemeConfigurationPropsParser
} from "./liveblog";
import {LiveblogTeaserThemeConfiguration, liveblogTeaserThemeConfigurationDefaultValues} from "./teaser";
import {
  SharedThemeConfiguration,
  sharedThemeConfigurationDefaultValues,
  sharedThemeConfigurationPropsParser
} from "./shared";

export type {LiveblogThemeConfiguration, SortOrder, RefreshType} from "./liveblog";
export type {LiveblogTeaserThemeConfiguration} from "./teaser";
export type {SharedThemeConfiguration} from "./shared";
export {sharedThemeConfigurationDefaultValues, sharedThemeConfigurationPropsParser} from "./shared";
export {liveblogThemeConfigurationDefaultValues, liveblogThemeConfigurationPropsParser, liveblogWidgetConfigurationLimitMax, liveblogWidgetConfigurationLimitMin, sortValues, refreshValues} from "./liveblog";
export {liveblogTeaserThemeConfigurationDefaultValues, liveblogTeaserThemeConfigurationPropsParser} from "./teaser";

// Liveblog Core Widget

export interface LiveblogCoreWidgetInitialData {
  gameShowResponse?: IGameShowResponse
  uniqueId?: string
  isSSR?: boolean
}

// Liveblog Widget

export interface LiveblogWidgetInitialData extends LiveblogCoreWidgetInitialData {
  configuration?: LiveblogThemeConfiguration & SharedThemeConfiguration
  css?: string
  liveblogUrl?: string
}

export interface LiveblogTeaserWidgetInitialData extends LiveblogCoreWidgetInitialData {
  configuration?: LiveblogTeaserThemeConfiguration & SharedThemeConfiguration
  css?: string
  liveblogUrl?: string
}

// OUTPUT CHANNEL TEMPLATES

export interface ThemeV1 {
  _id: string
  name: string

  configuration: LiveblogThemeConfiguration & SharedThemeConfiguration

  customStyleId?: string
  ownerId: string
  isDefault?: boolean
  createdAt?: number
  updatedAt?: number
  updatedBy?: string
  deletedAt?: number
}

export interface ThemeV2 {
  _id: string
  name: string

  sharedConfiguration: SharedThemeConfiguration
  liveblogConfiguration: LiveblogThemeConfiguration
  liveblogTeaserConfiguration: LiveblogTeaserThemeConfiguration

  customStyleId?: string
  ownerId: string
  isDefault?: boolean
  createdAt?: number
  updatedAt?: number
  updatedBy?: string
  deletedAt?: number
}

export type Theme = ThemeV1 | ThemeV2

export interface ThemeList<T extends Theme = Theme> {
  items: T[]
}

export interface ThemeUpdatePayload {
  name?: string
  customStyleId?: string

  sharedConfiguration?: SharedThemeConfiguration
  liveblogConfiguration?: LiveblogThemeConfiguration
  liveblogTeaserConfiguration?: LiveblogTeaserThemeConfiguration
}

export interface ThemeCreatePayload {
  name?: string
  customStyleId?: string

  sharedConfiguration?: SharedThemeConfiguration
  liveblogConfiguration?: LiveblogThemeConfiguration
  liveblogTeaserConfiguration?: LiveblogTeaserThemeConfiguration
}


export interface CustomStyle {
  _id: string
  ownerId: string
  name: string
  css: string
  createdAt?: number
  updatedAt?: number
  updatedBy?: string
  deletedAt?: number
  deletedBy?: string
}

export interface CustomStyleList {
  items: CustomStyle[]
}

export interface CustomStyleUpdatePayload {
  name: string
  css: string
}

export interface CustomStyleCreatePayload {
  name: string
  css: string
}

export interface IMultiMediaDisplayTypeOption {
  label: string
  value: string
}

export enum MultiMediaDisplayTypeOptionEnums {
  DEFAULT = "default",
  SLIDESHOW = "slideshow",
}

function isThemeV2(theme: Theme): theme is ThemeV2 {
  return 'sharedConfiguration' in theme;
}

export function upgradeThemeToV2(theme: Theme): ThemeV2 {
  if(isThemeV2(theme)) {
    return theme;
  }
  return {
    _id: theme._id,
    name: theme.name,
    ownerId: theme.ownerId,
    sharedConfiguration: {
      // Copy over valid parameters at development time of V2. no need to add new fields here
      ...sharedThemeConfigurationDefaultValues,
      locale: theme.configuration.locale,
      colorScheme: theme.configuration.colorScheme,
      useCookies: theme.configuration.useCookies,
    },
    liveblogConfiguration: {
      // Copy over valid parameters at development time of V2. no need to add new fields here
      ...liveblogThemeConfigurationDefaultValues,
      refresh: theme.configuration.refresh,
      sort: theme.configuration.sort,
      limit: theme.configuration.limit,
      deepLinkLimit: theme.configuration.deepLinkLimit,
      deepLinkDepth: theme.configuration.deepLinkDepth,
      webEmbedDefaultConstraint: theme.configuration.webEmbedDefaultConstraint,
      webEmbedConstraints: theme.configuration.webEmbedConstraints,
      useSlideshow: theme.configuration.useSlideshow,
      automaticSummary: theme.configuration.automaticSummary,
      automaticSummaryHighlightsLimit: theme.configuration.automaticSummaryHighlightsLimit,
      eventTagNavigation: theme.configuration.eventTagNavigation,
      sharing: theme.configuration.sharing,
      layout: theme.configuration.layout,
      layoutAuthorPosition: theme.configuration.layoutAuthorPosition,
      layoutEventTagPosition: theme.configuration.layoutEventTagPosition,
      styleUseCards: theme.configuration.styleUseCards,
      styleInvertTime: theme.configuration.styleInvertTime,
      styleInvertHighlightEvents: theme.configuration.styleInvertHighlightEvents,
      styleInvertStickyEvents: theme.configuration.styleInvertStickyEvents,
      styleInvertSummaryEvents: theme.configuration.styleInvertSummaryEvents,
      styleBgColor: theme.configuration.styleBgColor,
      styleBgColorDark: theme.configuration.styleBgColorDark,
      styleEventHighlightBgColor: theme.configuration.styleEventHighlightBgColor,
      styleEventHighlightBgColorDark: theme.configuration.styleEventHighlightBgColorDark,
      styleEventStickyBgColor: theme.configuration.styleEventStickyBgColor,
      styleEventStickyBgColorDark: theme.configuration.styleEventStickyBgColorDark,
      styleEventSummaryBgColor: theme.configuration.styleEventSummaryBgColor,
      styleEventSummaryBgColorDark: theme.configuration.styleEventSummaryBgColorDark,
      stylePrimaryColor: theme.configuration.stylePrimaryColor,
      stylePrimaryColorDark: theme.configuration.stylePrimaryColorDark,
      styleSecondaryColor: theme.configuration.styleSecondaryColor,
      styleSecondaryColorDark: theme.configuration.styleSecondaryColorDark,
      styleTextColor: theme.configuration.styleTextColor,
      styleTextColorDark: theme.configuration.styleTextColorDark,
      styleTextMutedColor: theme.configuration.styleTextMutedColor,
      styleTextMutedColorDark: theme.configuration.styleTextMutedColorDark,
      commentsMode: theme.configuration.commentsMode
    },
    liveblogTeaserConfiguration: liveblogTeaserThemeConfigurationDefaultValues,
    customStyleId: theme.customStyleId,
    isDefault: theme.isDefault,
    updatedBy: theme.updatedBy,
    createdAt: theme.createdAt,
    deletedAt: theme.deletedAt,
    updatedAt: theme.updatedAt,
  }
}

export function ensureThemeDefaults<T extends ThemeV1|ThemeV2>(theme: T): T {
  if(isThemeV2(theme)) {
    return {
      ...theme,
      sharedConfiguration: {...sharedThemeConfigurationDefaultValues, ...theme.sharedConfiguration},
      liveblogConfiguration: {...liveblogThemeConfigurationDefaultValues, ...theme.liveblogConfiguration},
      liveblogTeaserConfiguration: {...liveblogTeaserThemeConfigurationDefaultValues, ...theme.liveblogTeaserConfiguration}
    }
  } else {
    return {
      ...theme,
      configuration: {...liveblogThemeConfigurationDefaultValues, ...sharedThemeConfigurationDefaultValues, ...theme.configuration}
    }
  }
}
