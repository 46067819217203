import {TickarooLiveblogElement} from "./TickarooLiveblogElement";
import {TickarooLiveblogTeaserElement} from "./TickarooLiveblogTeaserElement";

export function startCustomElementSetup() {

  if(!customElements.get('tickaroo-liveblog')){
    window.customElements.define("tickaroo-liveblog", TickarooLiveblogElement)
  }

  if(!customElements.get('tickaroo-liveblog-teaser')){
    window.customElements.define("tickaroo-liveblog-teaser", TickarooLiveblogTeaserElement)
  }
}
