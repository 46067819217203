import React, {useState, ReactNode} from "react";
import useBreakpoints from "@webng/react-app-common/src/utils/useBreakpoints";
import { SlickNextArrow, SlickPrevArrow } from "../content/MultiMediaSlider";
import { TeaserSliderWrapper } from "./TeaserWidgetReactSlickLazy";



interface TeaserWidgetSliderProps {
  highlightCount: number
  children: ReactNode
}

export function TeaserWidgetSlider({highlightCount, children}: TeaserWidgetSliderProps) {
  const [lastSlides, setLastSlides] = useState<boolean>();

  const {ref: sizeRef, breakpoint: breakpoint} = useBreakpoints([
    ["", 576],
    ["sm", 768],
    ["md", 992],
    ["lg", 1200],
    ["xl", Infinity]
  ]);

  let slidesToShow, slidesToScroll;
  switch (breakpoint) {
    case "sm":
      slidesToShow = 2;
      slidesToScroll = 2;
      break;
    case "md":
      slidesToShow = 3;
      slidesToScroll = 3;
      break;
    case "lg":
      slidesToShow = 4;
      slidesToScroll = 4;
      break;
    case "xl":
      slidesToShow = 5;
      slidesToScroll = 5;
      break;

    default:
      slidesToShow = 1;
      slidesToScroll = 1;
      break;
  }

  if (!highlightCount) {
    return <></>;
  }


  // limit to highlightCount max;
  slidesToShow = highlightCount && slidesToShow > highlightCount ? highlightCount : slidesToShow;
  const slideActive = highlightCount && slidesToShow < highlightCount;

  const possibleLastIndex =  highlightCount - slidesToShow;

  const settings = {
    adaptiveHeight: false,
    infinite: false,
    speed: 150,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    className: `
      tik4-sl tik4-tws__sl
      ${lastSlides ? 'tik4-tws__sl--last-slides' : ''}
      ${slideActive ? 'tik4-tws__sl--slide-active' : ''}
      `,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />,
    dots: true,
    appendDots: (dots:React.ReactNode) => <ul className="tik4-sl__d">{dots}</ul>,
        dotsClass: "tik4-sl__d",
    customPaging: (i:number) => (
      <button className="tik4-sl__d__d">
        <div className="tik4-sl__d__d__i">{i+1}</div>
      </button>
    ),
    beforeChange: (current:any, next:any) => {
      setLastSlides(next >= possibleLastIndex);
    }
  }

  return <React.Fragment>
    {highlightCount && highlightCount > 0 &&
        <div className="tik4-tws" ref={sizeRef} style={{"--t4-tws-stsc": slidesToScroll} as React.CSSProperties}>
          <TeaserSliderWrapper {...settings} >
            {children}
          </TeaserSliderWrapper>
        </div>
      }
  </React.Fragment>
}
