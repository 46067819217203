import React, {useCallback, useEffect, useState} from "react";
import {Check, Copy, Facebook, Link, Mail, Share2, X} from "react-feather";
import {XSocial} from "@webng/icons-common";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {useApi} from "@webng/react-app-common";
import {EventSharingType, useTrackingFunction} from "../LiveblogRenderContext";
import {ApiClient} from "@webng/tickaroo-api";

interface EventSharingProps {
  type: EventSharingType
  eventId: string
}


// check may not be good enough

// const AndroidUserAgentRegex = new RegExp('Android.*(wv|.0.0.0)', 'ig');
//
// function checkAndroidWebview(userAgent: string){
//   return !!userAgent.match(AndroidUserAgentRegex);
// }

export default function EventSharing({eventId, type}: EventSharingProps) {
  const {t} = useTranslation("liveblog");
  const [sharingExpanded, setSharingExpanded] = useState(false)
  const [justCopied, setJustCopied] = useState(false);
  const [safariHack, setSafariHack] = useState<string|undefined>();
  const [isNativeShareSupported, setIsNativeShareSupported] = useState(false);
  const compactSharing = type === "compact";
  const ariaId = `tickaroo-sharing-${eventId}`
  const [isSsr, setIsSsr] = useState(true);
  const apiClient = useApi();
  const trackingFunction = useTrackingFunction()

  useEffect(() => {
    setIsSsr(false);
  }, [setIsSsr])

  useEffect(() => {
    if(navigator.share !== undefined && navigator.canShare !== undefined && navigator.canShare({ url: "https://www.tickaroo.com/" })){
      setIsNativeShareSupported(true);
    }
  }, []);

  const hasAndroidNativeCopy = !isSsr && !!(window as any).tik4Android && !!(window as any).tik4Android.copyToClipboard;
  const linkCopySupported = true; // isSsr || !isAndroidWebview || hasAndroidNativeCopy;

  const onSafariCopy = useCallback(async () => {
    await navigator.clipboard.writeText(safariHack!)
    setJustCopied(true)
    setTimeout(() => {
      setJustCopied(false);
      setSafariHack(undefined)
    }, 2000)
    setTimeout(() => {
      setSharingExpanded(false);
    }, 1700)
  }, [safariHack])

  const copyLink = useCallback(async () => {
    trackingFunction({t: 'a_scp', e: eventId })
    const result = await apiClient.postJson(ApiClient.API_V5_SHARING_POST, {
      reftype: 'Event',
      refid: eventId,
      source_url: window.location.href
    }, {})
    try {
      if(hasAndroidNativeCopy){
        await (window as any).tik4Android.copyToClipboard(result.url);
      } else {
        await navigator.clipboard.writeText(result.url)
      }
      setJustCopied(true);
      setTimeout(() => {
        setJustCopied(false);
      }, 2000)
      setTimeout(() => {
        setSharingExpanded(false);
      }, 1700)
    } catch (e) {
      setSafariHack(result.url)
    }
  }, [apiClient, eventId, trackingFunction, hasAndroidNativeCopy])

  const mailLink = useCallback(() => {
    trackingFunction({t: 'a_sem', e: eventId })
    apiClient.postJson(ApiClient.API_V5_SHARING_POST, {
      reftype: 'Event',
      refid: eventId,
      source_url: window.location.href
    }, {}).then(result => {
      setSharingExpanded(false)
      window.location.href = 'mailto:?body=' + encodeURIComponent(result.url)
    })

  }, [eventId, apiClient, trackingFunction])

  const shareNative = useCallback(async () => {
    trackingFunction({t: 'a_sna', e: eventId })
    const result = await apiClient.postJson(ApiClient.API_V5_SHARING_POST, {
      reftype: 'Event',
      refid: eventId,
      source_url: window.location.href
    }, {})
    try {
      await navigator.share({
        url: result.url,
      })
    } catch (error) {
      //ignored
    }
  }, [eventId, apiClient, trackingFunction])

  const shareTwitter = useCallback(() => {
    trackingFunction({t: 'a_sxx', e: eventId })
    const url = apiClient._apiUrl(ApiClient.API_SHARE_REDIRECT, {eventId, target: 'twitter', source_url: window.location.href})
    window.open(url, '', 'scrollbars=yes, width=600, height=300')
    setSharingExpanded(false)
  }, [eventId, apiClient, trackingFunction])

  const shareFacebook = useCallback(() => {
    trackingFunction({t: 'a_sfb', e: eventId })
    const url = apiClient._apiUrl(ApiClient.API_SHARE_REDIRECT, {eventId, target: 'facebook', source_url: window.location.href})
    window.open(url)
    setSharingExpanded(false)
  }, [eventId, apiClient, trackingFunction])

  const toggleSharingExpanded = useCallback(() => {
    setSharingExpanded(!sharingExpanded)
  }, [setSharingExpanded, sharingExpanded])

  return <div className={classNames("tik4-sharing", {
    'tik4-sharing--open': compactSharing && sharingExpanded,
    'tik4-sharing--close': compactSharing && !sharingExpanded
  })}>
    <div className="tik4-sharing__items" id={ariaId} role="menu">
      {linkCopySupported && <div className="tik4-sharing__item tik4-sharing__item--link">
        {safariHack &&
          <div className="tik4-sharing__item__sh">
            <input className="tik4-sharing__item__sh__field tik4-form-field tik4-form-field--sm" value={safariHack}
                   readOnly={true}/>
            <button className="tik4-sharing__item__sh__button tik4-button tik4-button--primary tik4-button--sm"
                    onClick={onSafariCopy} type="button">
              {justCopied
                ?
                <>
                  <Check className="tik4-icon tik4-icon--mr"/>
                  {t('liveblog.event.sharing.items.link.copied')}
                </>
                :
                <>
                  <Copy className="tik4-icon tik4-icon--mr"/>
                  {t('liveblog.event.sharing.items.link.copy')}
                </>
              }
            </button>
            <button className="tik4-sharing__item__sh__close" title={t('liveblog.event.sharing.items.close.title')}
                    onClick={() => setSafariHack(undefined)} type="button">
              <X className="tik4-icon"/>
            </button>
          </div>
        }

        <button className="tik4-sharing__item__button" title={t('liveblog.event.sharing.items.link.title')}
                role="menuitem" onClick={copyLink} type="button">
          <Link className="tik4-icon tik4-sharing__item__icon"/>
          <span className="tik4-sharing__item__text">{t('liveblog.event.sharing.items.link.text')}</span>
        </button>
        <div
          className={`tik4-sharing__item__tooltip ${justCopied ? 'tik4-sharing__item__tooltip--copied' : ''}`}>{justCopied ? t('liveblog.event.sharing.items.link.copied') : t('liveblog.event.sharing.items.link.copy')}</div>
      </div>}
      <div className="tik4-sharing__item tik4-sharing__item--facebook">
        <button className="tik4-sharing__item__button" title={t('liveblog.event.sharing.items.facebook.title')} role="menuitem" onClick={shareFacebook} type="button">
          <Facebook className="tik4-icon tik4-sharing__item__icon" />
          <span className="tik4-sharing__item__text">{t('liveblog.event.sharing.items.facebook.text')}</span>
        </button>
        <div className="tik4-sharing__item__tooltip">{t('liveblog.event.sharing.items.facebook.tooltip')}</div>
      </div>
      <div className="tik4-sharing__item tik4-sharing__item--twitter">
        <button className="tik4-sharing__item__button" title={t('liveblog.event.sharing.items.twitter.title')} role="menuitem" onClick={shareTwitter} type="button">
          <XSocial className="tik4-icon tik4-sharing__item__icon" />
          <span className="tik4-sharing__item__text">{t('liveblog.event.sharing.items.twitter.text')}</span>
        </button>
        <div className="tik4-sharing__item__tooltip">{t('liveblog.event.sharing.items.twitter.tooltip')}</div>
      </div>
      <div className={`tik4-sharing__item tik4-sharing__item--email ${isNativeShareSupported ? "" : "tik4-sharing__item--last"}`}>
        <button className="tik4-sharing__item__button" title={t('liveblog.event.sharing.items.email.title')} role="menuitem" onClick={mailLink} type="button">
          <Mail className="tik4-icon tik4-sharing__item__icon" />
          <span className="tik4-sharing__item__text">{t('liveblog.event.sharing.items.email.text')}</span>
        </button>
        <div className="tik4-sharing__item__tooltip">{t('liveblog.event.sharing.items.email.tooltip')}</div>
      </div>
      { isNativeShareSupported &&
        <div className="tik4-sharing__item tik4-sharing__item--native tik4-sharing__item--last">
          <button className="tik4-sharing__item__button" title={t('liveblog.event.sharing.items.native.title')} role="menuitem" onClick={shareNative} type="button">
            <Share2 className="tik4-icon tik4-sharing__item__icon"/>
            <span className="tik4-sharing__item__text">{t('liveblog.event.sharing.items.native.text')}</span>
          </button>
          <div className="tik4-sharing__item__tooltip">{t('liveblog.event.sharing.items.native.tooltip')}</div>
        </div>
      }

      { compactSharing &&
        <div className="tik4-sharing__items__close">
          <button className="tik4-sharing__items__close__button" title={t('liveblog.event.sharing.items.close.title')} onClick={toggleSharingExpanded}
                  aria-controls={ariaId}
                  aria-haspopup="true"
                  aria-expanded={sharingExpanded} type="button">
            <X className="tik4-icon tik4-sharing__items__close__icon" />
            <span className="tik4-sharing__items__close__text">{t('liveblog.event.sharing.items.close.text')}</span>
          </button>
        </div>
      }
    </div>

    { compactSharing &&
      <div className="tik4-sharing__open">
        <button className="tik4-sharing__open__button"
                onClick={toggleSharingExpanded}
                aria-controls={ariaId}
                aria-haspopup="true"
                aria-expanded={sharingExpanded} type="button">
          <Share2 className="tik4-icon tik4-sharing__open__icon" />
          <span className="tik4-sharing__open__text">{t('liveblog.event.sharing.open.text')}</span>
        </button>
      </div>
    }

  </div>
}
